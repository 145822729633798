import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakEvent, KeycloakEventType, KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { Subject, filter, firstValueFrom } from 'rxjs';
import { clearLocalStorageExcept } from '../../utils/utils';

@Injectable({
  providedIn: 'root',
})
export class KeycloakWrapperService {
  constructor(private keycloakService: KeycloakService, private http: HttpClient) {
    keycloakService.keycloakEvents$
      .pipe(filter((event) => event.type === KeycloakEventType.OnTokenExpired))
      .subscribe(() => {
        console.log('expired token ' + new Date());
        keycloakService
          .updateToken(5)
          .then((refreshed) => {
            if (refreshed) {
              console.log('refreshed ' + new Date());
              const token = keycloakService.getKeycloakInstance().token;
              if (token) localStorage.setItem('token', token);
              const refreshToken = keycloakService.getKeycloakInstance().refreshToken;
              if (refreshToken) localStorage.setItem('refreshToken', refreshToken);
            } else {
              console.error('Failed to refresh access token ' + new Date());
            }
          })
          .catch(() => {
            console.error('Failed to refresh access token ' + new Date());
          });
      });
  }

  public idleTimeLogout() {
    clearLocalStorageExcept([
      'account',
      'transactions',
      'beneficiary',
      'notifications',
      'lastLoggedInId',
      'APP_PREFERENCES',
      'firstTime',
    ]);
    this.keycloakService.logout(window.location.origin + '/auth');
  }

  public login(options?: Keycloak.KeycloakLoginOptions): Promise<void> {
    return this.keycloakService.login(options);
  }

  public init(options?: KeycloakOptions): Promise<boolean> {
    return this.keycloakService.init(options);
  }

  public get keycloakEvents$(): Subject<KeycloakEvent> {
    return this.keycloakService.keycloakEvents$;
  }

  public updateToken(minValidity?: number | undefined): Promise<boolean> {
    return this.keycloakService.updateToken(minValidity);
  }

  public getToken(): Promise<string> {
    return this.keycloakService.getToken();
  }

  public logout(redirectUri?: string | undefined): Promise<void> {
    clearLocalStorageExcept([
      'account',
      'transactions',
      'beneficiary',
      'notifications',
      'lastLoggedInId',
      'APP_PREFERENCES',
      'firstTime',
    ]);
    return this.keycloakService.logout(redirectUri);
  }

  public isLoggedIn(): boolean {
    return this.keycloakService.isLoggedIn();
  }

  public getKeycloakInstance(): Keycloak.KeycloakInstance {
    return this.keycloakService.getKeycloakInstance();
  }

  public isTokenExpired(minValidity?: number): boolean {
    return this.keycloakService.isTokenExpired(minValidity);
  }

  /**
   * Generic method to call endpoints from our custom keycloak extension
   * Includes logic for handling proxy for extension if running locally
   *
   * @param method
   * @param endpoint
   * @param options
   */
  public async useCustomKeycloakService<T>(
    method: 'GET' | 'POST' | 'PATCH',
    endpoint: string,
    options?: {
      data?: any;
      params?: any;
      headers?: Record<string, string>;
    },
  ): Promise<T> {
    const token: string = await this.getToken();
    const keycloak: Keycloak.KeycloakInstance = this.getKeycloakInstance();

    // @ts-ignore
    const isLocalKeycloak: boolean = keycloak?.authServerUrl.includes('localhost:8088'); // Check for local keycloak
    // Use relative path for localhost (proxy will handle it)
    // Use absolute path for all other environments
    const url: string = isLocalKeycloak
      ? `/auth/realms/${keycloak.realm}/${endpoint}`
      : `${keycloak.authServerUrl}/realms/${keycloak.realm}/${endpoint}`;

    return firstValueFrom(
      this.http.request<T>(method, url, {
        body: options?.data,
        params: options?.params,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          ...options?.headers,
        },
      }),
    );
  }
}
