export type TripPayload = {
  start_date: string;
  end_date: string;
  mode_of_travel: string;
  departure_terminal: string;
  country: string;
  trip_name: string;
  country_entry: string;
  country_exit: string;
  country_destination: string;
  departure_dt: string;
  vessel_number: number;
  approxTime: string;
  document_id: string;
};

export enum ReceiptStatus {
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Processing = 'Processing',
  Failed = 'Failed',
  ImproperReceipt = 'Improper Receipt',
  UnderReview = 'Under Review',
  IrrelevantReceipt = 'Irrelevant Receipt',
  LineItemTotalMismatch = 'Receipt line item and receipt total mismatch',
  TransactionAmountReceiptAmountMismatch = 'Transaction amount and receipt amount mismatch',
}

interface TransactionReceipt {
  status: ReceiptStatus;
  error: string;
}

export enum RevenirPassportKYCStatus {
  PASSED = 'passed',
  PROCESSING = 'processing',
}

export type RevenirTransactionType = {
  receipt: TransactionReceipt;
  account_number: string;
  archived: boolean;
  country: string;
  merchant_name: string;
  transaction_category: string;
  transaction_date: string;
  total_transaction_reclaim: string;
  total_amount: number;
  receipt_status: string;
  total_vat_amount: number;
  transaction_id: string;
  iconStatus: string;
  isError: boolean;
  currency: string;

  receipt_failed_reason: string; // requested
  vat_reclaim_status: string; // requested
};

export type RevenirTransactionItemType = {
  item_description: string;
  category: string;
  eligible: boolean;
  quantity: number;
  discount: number;
  tax: number;
  vat_rate: number;
  price: number;
  total: number;
  vat_amount: string;
  currency: string; // requested
};

export type TripDetailsType = {
  archived: boolean;
  is_boarding_pass_uploaded: boolean;
  country_entry: string;
  country_exit: string;
  departure_terminal: string;
  country_destination: string;
  mode_of_travel: string;
  total_reclaim: number;
  barcode_status: string;
  trip_start_date: string;
  trip_end_date: string;
  transactions: RevenirTransactionType[];
};

export enum TripReclaimStatusEnum {
  PROCESSING = 'Processing',
  REFUND_APPROVED = 'Refund Approved',
  REFUND_FAILED = 'Refund Failed',
  REFUND_PAID = 'Refund Paid',
}

export interface RevenirTripType extends TripDetailsType {
  archived: boolean;
  is_boarding_pass_uploaded: boolean;
  trip_id: string;
  trip_name: string;
  start_date: string;
  end_date: string;
  country: string;
  trip_status: string;
  vat_reclaim_status: TripReclaimStatusEnum;
  url?: string;
  countryName?: string;
}
export type TripsList = {
  trips: RevenirTripType[];
};

export type Ports = {
  name: string;
  code: string;
};

export type ExitPortListType = {
  [countryCode: string]: {
    [modeOfTravel: string]: Ports[];
  };
};

export type UploadBoardingPassResponse = {
  trip_id: string;
  reference_number: string;
  destination: string;
  document_id: string;
};

export const imageUrlFromImageName = (imagePath: string, countryCode: string): string => {
  const lowerCaseImageName = getRevenirCountryObject(countryCode, 'countryCode')?.countryName;

  if (lowerCaseImageName) {
    return `/assets/${imagePath}/${lowerCaseImageName}.jpeg`;
  } else {
    return `/assets/${imagePath}/no-country-image.svg`;
  }
};

export const getRevenirCountryObject = (value: string, key: 'countryCode' | 'countryName') => {
  const countries = [
    { countryCode: 'FRA', countryName: 'France' },
    { countryCode: 'BEL', countryName: 'Belgium' },
    { countryCode: 'ITA', countryName: 'Italy' },
    { countryCode: 'NLD', countryName: 'Netherlands' },
    { countryCode: 'PRT', countryName: 'Portugal' },
    { countryCode: 'ESP', countryName: 'Spain' },
  ];
  return countries.find((x) => x[key]?.toLowerCase() == value?.toLowerCase());
};

export const revenirModeOfTravelkey = (modeOfTravel: string) => {
  const modeOfTravelOptions = [
    { key: 'Airport', value: 'air' },
    { key: 'Railway station', value: 'train' },
    { key: 'Road border crossing', value: 'Road border crossing' },
    { key: 'Seaport', value: 'sea' },
    { key: 'Other', value: 'other' },
  ];
  return modeOfTravelOptions.find((value) => value.key === modeOfTravel);
};

export interface DropdownOption {
  id: string;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface RevenirSubjectStatusResponse {
  kyc_status: RevenirPassportKYCStatus;
}
