<div [ngStyle]="contentStyle" class="section password-forgot pointer-events-none">
  <p *ngIf="dataIn?.attri?.widgetProperties?.subHeadingLabel" [ngStyle]="mergedHeadingStyle">
    {{ dataIn?.attri?.widgetProperties?.subHeadingLabel }}
  </p>
  <div class="text-left mt-3">
    <form class="beneficiary-form" [formGroup]="passwordForm">
      <div [ngStyle]="inputContentStyle">
        <finx-input
          type="password"
          class="profile-input"
          formControlName="newPassword"
          [colorTextAlignStyle]="colorTextAlignStyle"
          [labelAsPlaceholder]="false"
          [label]="dataIn?.attri?.widgetProperties?.passFieldLabel1 ?? ''"
          [placeholder]="dataIn?.attri?.widgetProperties?.passFieldPC1 ?? ''"
          [inputStyle]="newPasswordInputStyle"
          (focusEvent)="updateInputFieldCurrentStyles($event, 'newPassword')"
        ></finx-input>
        <div class="mt-3">
          <finx-input
            type="password"
            class="profile-input mt-3"
            formControlName="confirmPassword"
            [colorTextAlignStyle]="colorTextAlignStyle"
            [labelAsPlaceholder]="false"
            [label]="dataIn?.attri?.widgetProperties?.passFieldLabel2 ?? ''"
            [placeholder]="dataIn?.attri?.widgetProperties?.passFieldPC2 ?? ''"
            [inputStyle]="confirmPasswordInputStyle"
            (focusEvent)="updateInputFieldCurrentStyles($event, 'confirmPassword')"
          ></finx-input>
        </div>
        <button
          class="nested-button"
          [ngClass]="[
            pageConfig?.isUsingHtmlStructure
              ? 'w-100'
              : !isAllowedGlobalStyling
              ? 'position-absolute-fill-left-right'
              : '',
            isAllowedGlobalStyling ? globalStylingClass : ''
          ]"
          (click)="enableSubmit = true; onSubmit()"
          [ngStyle]="!isAllowedGlobalStyling ? reviewDetailsButtonStyle : {}"
          type="submit"
          [innerHTML]="buttonContent"
        ></button>
      </div>
    </form>
  </div>
</div>
