/** All locales with the dashes and capitalization, e.g. `en-US`, `zh-Hant-HK`. */
export enum Locale {
  af = 'af',
  af_NA = 'af-NA',
  af_ZA = 'af-ZA',
  agq = 'agq',
  agq_CM = 'agq-CM',
  ak = 'ak',
  ak_GH = 'ak-GH',
  am = 'am',
  am_ET = 'am-ET',
  ar = 'ar',
  ar_001 = 'ar-001',
  ar_AE = 'ar-AE',
  ar_BH = 'ar-BH',
  ar_DZ = 'ar-DZ',
  ar_EG = 'ar-EG',
  ar_IQ = 'ar-IQ',
  ar_JO = 'ar-JO',
  ar_KW = 'ar-KW',
  ar_LB = 'ar-LB',
  ar_LY = 'ar-LY',
  ar_MA = 'ar-MA',
  ar_OM = 'ar-OM',
  ar_QA = 'ar-QA',
  ar_SA = 'ar-SA',
  ar_SD = 'ar-SD',
  ar_SY = 'ar-SY',
  ar_TN = 'ar-TN',
  ar_YE = 'ar-YE',
  as = 'as',
  as_IN = 'as-IN',
  asa = 'asa',
  asa_TZ = 'asa-TZ',
  az = 'az',
  az_Cyrl = 'az-Cyrl',
  az_Cyrl_AZ = 'az-Cyrl-AZ',
  az_Latn = 'az-Latn',
  az_Latn_AZ = 'az-Latn-AZ',
  bas = 'bas',
  bas_CM = 'bas-CM',
  be = 'be',
  be_BY = 'be-BY',
  bem = 'bem',
  bem_ZM = 'bem-ZM',
  bez = 'bez',
  bez_TZ = 'bez-TZ',
  bg = 'bg',
  bg_BG = 'bg-BG',
  bm = 'bm',
  bm_ML = 'bm-ML',
  bn = 'bn',
  bn_BD = 'bn-BD',
  bn_IN = 'bn-IN',
  bo = 'bo',
  bo_CN = 'bo-CN',
  bo_IN = 'bo-IN',
  br = 'br',
  br_FR = 'br-FR',
  brx = 'brx',
  brx_IN = 'brx-IN',
  bs = 'bs',
  bs_BA = 'bs-BA',
  ca = 'ca',
  ca_ES = 'ca-ES',
  cgg = 'cgg',
  cgg_UG = 'cgg-UG',
  chr = 'chr',
  chr_US = 'chr-US',
  cs = 'cs',
  cs_CZ = 'cs-CZ',
  cy = 'cy',
  cy_GB = 'cy-GB',
  da = 'da',
  da_DK = 'da-DK',
  dav = 'dav',
  dav_KE = 'dav-KE',
  de = 'de',
  de_AT = 'de-AT',
  de_BE = 'de-BE',
  de_CH = 'de-CH',
  de_DE = 'de-DE',
  de_LI = 'de-LI',
  de_LU = 'de-LU',
  dje = 'dje',
  dje_NE = 'dje-NE',
  dua = 'dua',
  dua_CM = 'dua-CM',
  dyo = 'dyo',
  dyo_SN = 'dyo-SN',
  ebu = 'ebu',
  ebu_KE = 'ebu-KE',
  ee = 'ee',
  ee_GH = 'ee-GH',
  ee_TG = 'ee-TG',
  el = 'el',
  el_CY = 'el-CY',
  el_GR = 'el-GR',
  en = 'en',
  en_AE = 'en-AE',
  en_AR = 'en-AR',
  en_AS = 'en-AS',
  en_AT = 'en-AT',
  en_AU = 'en-AU',
  en_BB = 'en-BB',
  en_BE = 'en-BE',
  en_BG = 'en-BG',
  en_BH = 'en-BH',
  en_BM = 'en-BM',
  en_BR = 'en-BR',
  en_BW = 'en-BW',
  en_BZ = 'en-BZ',
  en_CA = 'en-CA',
  en_CH = 'en-CH',
  en_CL = 'en-CL',
  en_CN = 'en-CN',
  en_CO = 'en-CO',
  en_CR = 'en-CR',
  en_CY = 'en-CY',
  en_CZ = 'en-CZ',
  en_DE = 'en-DE',
  en_DK = 'en-DK',
  en_DO = 'en-DO',
  en_EE = 'en-EE',
  en_EG = 'en-EG',
  en_ES = 'en-ES',
  en_FI = 'en-FI',
  en_GB = 'en-GB',
  en_GE = 'en-GE',
  en_GF = 'en-GF',
  en_GH = 'en-GH',
  en_GI = 'en-GI',
  en_GR = 'en-GR',
  en_GU = 'en-GU',
  en_GY = 'en-GY',
  en_HK = 'en-HK',
  en_HR = 'en-HR',
  en_HU = 'en-HU',
  en_ID = 'en-ID',
  en_IE = 'en-IE',
  en_IL = 'en-IL',
  en_IN = 'en-IN',
  en_IS = 'en-IS',
  en_IT = 'en-IT',
  en_JM = 'en-JM',
  en_JO = 'en-JO',
  en_JP = 'en-JP',
  en_KR = 'en-KR',
  en_KW = 'en-KW',
  en_KY = 'en-KY',
  en_LB = 'en-LB',
  en_LI = 'en-LI',
  en_LT = 'en-LT',
  en_LU = 'en-LU',
  en_LV = 'en-LV',
  en_MA = 'en-MA',
  en_MC = 'en-MC',
  en_MG = 'en-MG',
  en_MH = 'en-MH',
  en_MK = 'en-MK',
  en_MO = 'en-MO',
  en_MP = 'en-MP',
  en_MT = 'en-MT',
  en_MU = 'en-MU',
  en_MX = 'en-MX',
  en_MY = 'en-MY',
  en_NA = 'en-NA',
  en_NL = 'en-NL',
  en_NO = 'en-NO',
  en_NZ = 'en-NZ',
  en_OM = 'en-OM',
  en_PE = 'en-PE',
  en_PH = 'en-PH',
  en_PK = 'en-PK',
  en_PL = 'en-PL',
  en_PR = 'en-PR',
  en_PT = 'en-PT',
  en_PY = 'en-PY',
  en_QA = 'en-QA',
  en_RO = 'en-RO',
  en_RU = 'en-RU',
  en_SA = 'en-SA',
  en_SE = 'en-SE',
  en_SG = 'en-SG',
  en_SK = 'en-SK',
  en_SI = 'en-SI',
  en_TH = 'en-TH',
  en_TR = 'en-TR',
  en_TT = 'en-TT',
  en_TW = 'en-TW',
  en_UA = 'en-UA',
  en_UM = 'en-UM',
  en_US = 'en-US',
  en_US_POSIX = 'en-US-POSIX',
  en_UY = 'en-UY',
  en_VE = 'en-VE',
  en_VI = 'en-VI',
  en_VN = 'en-VN',
  en_ZA = 'en-ZA',
  en_ZW = 'en-ZW',
  eo = 'eo',
  es = 'es',
  es_419 = 'es-419',
  es_AR = 'es-AR',
  es_BO = 'es-BO',
  es_CL = 'es-CL',
  es_CO = 'es-CO',
  es_CR = 'es-CR',
  es_DO = 'es-DO',
  es_EC = 'es-EC',
  es_ES = 'es-ES',
  es_GQ = 'es-GQ',
  es_GT = 'es-GT',
  es_HN = 'es-HN',
  es_MX = 'es-MX',
  es_NI = 'es-NI',
  es_PA = 'es-PA',
  es_PE = 'es-PE',
  es_PR = 'es-PR',
  es_PY = 'es-PY',
  es_SV = 'es-SV',
  es_US = 'es-US',
  es_UY = 'es-UY',
  es_VE = 'es-VE',
  et = 'et',
  et_EE = 'et-EE',
  eu = 'eu',
  eu_ES = 'eu-ES',
  ewo = 'ewo',
  ewo_CM = 'ewo-CM',
  fa = 'fa',
  fa_AF = 'fa-AF',
  fa_IR = 'fa-IR',
  ff = 'ff',
  ff_SN = 'ff-SN',
  fi = 'fi',
  fi_FI = 'fi-FI',
  fil = 'fil',
  fil_PH = 'fil-PH',
  fo = 'fo',
  fo_FO = 'fo-FO',
  fr = 'fr',
  fr_BE = 'fr-BE',
  fr_BF = 'fr-BF',
  fr_BI = 'fr-BI',
  fr_BJ = 'fr-BJ',
  fr_BL = 'fr-BL',
  fr_CA = 'fr-CA',
  fr_CD = 'fr-CD',
  fr_CF = 'fr-CF',
  fr_CG = 'fr-CG',
  fr_CH = 'fr-CH',
  fr_CI = 'fr-CI',
  fr_CM = 'fr-CM',
  fr_DJ = 'fr-DJ',
  fr_FR = 'fr-FR',
  fr_GA = 'fr-GA',
  fr_GF = 'fr-GF',
  fr_GN = 'fr-GN',
  fr_GP = 'fr-GP',
  fr_GQ = 'fr-GQ',
  fr_KM = 'fr-KM',
  fr_LU = 'fr-LU',
  fr_MC = 'fr-MC',
  fr_MF = 'fr-MF',
  fr_MG = 'fr-MG',
  fr_ML = 'fr-ML',
  fr_MQ = 'fr-MQ',
  fr_NE = 'fr-NE',
  fr_RE = 'fr-RE',
  fr_RW = 'fr-RW',
  fr_SN = 'fr-SN',
  fr_TD = 'fr-TD',
  fr_TG = 'fr-TG',
  fr_YT = 'fr-YT',
  ga = 'ga',
  ga_IE = 'ga-IE',
  gl = 'gl',
  gl_ES = 'gl-ES',
  gsw = 'gsw',
  gsw_CH = 'gsw-CH',
  gu = 'gu',
  gu_IN = 'gu-IN',
  guz = 'guz',
  guz_KE = 'guz-KE',
  gv = 'gv',
  gv_GB = 'gv-GB',
  ha = 'ha',
  ha_Latn = 'ha-Latn',
  ha_Latn_GH = 'ha-Latn-GH',
  ha_Latn_NE = 'ha-Latn-NE',
  ha_Latn_NG = 'ha-Latn-NG',
  haw = 'haw',
  haw_US = 'haw-US',
  he = 'he',
  he_IL = 'he-IL',
  hi = 'hi',
  hi_IN = 'hi-IN',
  hr = 'hr',
  hr_HR = 'hr-HR',
  hu = 'hu',
  hu_HU = 'hu-HU',
  hy = 'hy',
  hy_AM = 'hy-AM',
  id = 'id',
  id_ID = 'id-ID',
  ig = 'ig',
  ig_NG = 'ig-NG',
  ii = 'ii',
  ii_CN = 'ii-CN',
  is = 'is',
  is_IS = 'is-IS',
  it = 'it',
  it_CH = 'it-CH',
  it_IT = 'it-IT',
  ja = 'ja',
  ja_JP = 'ja-JP',
  jmc = 'jmc',
  jmc_TZ = 'jmc-TZ',
  ka = 'ka',
  ka_GE = 'ka-GE',
  kab = 'kab',
  kab_DZ = 'kab-DZ',
  kam = 'kam',
  kam_KE = 'kam-KE',
  kde = 'kde',
  kde_TZ = 'kde-TZ',
  kea = 'kea',
  kea_CV = 'kea-CV',
  khq = 'khq',
  khq_ML = 'khq-ML',
  ki = 'ki',
  ki_KE = 'ki-KE',
  kk = 'kk',
  kk_Cyrl = 'kk-Cyrl',
  kk_Cyrl_KZ = 'kk-Cyrl-KZ',
  kl = 'kl',
  kl_GL = 'kl-GL',
  kln = 'kln',
  kln_KE = 'kln-KE',
  km = 'km',
  km_KH = 'km-KH',
  kn = 'kn',
  kn_IN = 'kn-IN',
  ko = 'ko',
  ko_KR = 'ko-KR',
  kok = 'kok',
  kok_IN = 'kok-IN',
  ksb = 'ksb',
  ksb_TZ = 'ksb-TZ',
  ksf = 'ksf',
  ksf_CM = 'ksf-CM',
  kw = 'kw',
  kw_GB = 'kw-GB',
  lag = 'lag',
  lag_TZ = 'lag-TZ',
  lg = 'lg',
  lg_UG = 'lg-UG',
  ln = 'ln',
  ln_CD = 'ln-CD',
  ln_CG = 'ln-CG',
  lt = 'lt',
  lt_LT = 'lt-LT',
  lu = 'lu',
  lu_CD = 'lu-CD',
  luo = 'luo',
  luo_KE = 'luo-KE',
  luy = 'luy',
  luy_KE = 'luy-KE',
  lv = 'lv',
  lv_LV = 'lv-LV',
  mas = 'mas',
  mas_KE = 'mas-KE',
  mas_TZ = 'mas-TZ',
  mer = 'mer',
  mer_KE = 'mer-KE',
  mfe = 'mfe',
  mfe_MU = 'mfe-MU',
  mg = 'mg',
  mg_MG = 'mg-MG',
  mgh = 'mgh',
  mgh_MZ = 'mgh-MZ',
  mk = 'mk',
  mk_MK = 'mk-MK',
  ml = 'ml',
  ml_IN = 'ml-IN',
  mr = 'mr',
  mr_IN = 'mr-IN',
  ms = 'ms',
  ms_BN = 'ms-BN',
  ms_MY = 'ms-MY',
  mt = 'mt',
  mt_MT = 'mt-MT',
  mua = 'mua',
  mua_CM = 'mua-CM',
  my = 'my',
  my_MM = 'my-MM',
  naq = 'naq',
  naq_NA = 'naq-NA',
  nb = 'nb',
  nb_NO = 'nb-NO',
  nd = 'nd',
  nd_ZW = 'nd-ZW',
  ne = 'ne',
  ne_IN = 'ne-IN',
  ne_NP = 'ne-NP',
  nl = 'nl',
  nl_AW = 'nl-AW',
  nl_BE = 'nl-BE',
  nl_CW = 'nl-CW',
  nl_NL = 'nl-NL',
  nl_SX = 'nl-SX',
  nmg = 'nmg',
  nmg_CM = 'nmg-CM',
  nn = 'nn',
  nn_NO = 'nn-NO',
  nus = 'nus',
  nus_SD = 'nus-SD',
  nyn = 'nyn',
  nyn_UG = 'nyn-UG',
  om = 'om',
  om_ET = 'om-ET',
  om_KE = 'om-KE',
  or = 'or',
  or_IN = 'or-IN',
  pa = 'pa',
  pa_Arab = 'pa-Arab',
  pa_Arab_PK = 'pa-Arab-PK',
  pa_Guru = 'pa-Guru',
  pa_Guru_IN = 'pa-Guru-IN',
  pl = 'pl',
  pl_PL = 'pl-PL',
  ps = 'ps',
  ps_AF = 'ps-AF',
  pt = 'pt',
  pt_AO = 'pt-AO',
  pt_BR = 'pt-BR',
  pt_GW = 'pt-GW',
  pt_MZ = 'pt-MZ',
  pt_PT = 'pt-PT',
  pt_ST = 'pt-ST',
  rm = 'rm',
  rm_CH = 'rm-CH',
  rn = 'rn',
  rn_BI = 'rn-BI',
  ro = 'ro',
  ro_MD = 'ro-MD',
  ro_RO = 'ro-RO',
  rof = 'rof',
  rof_TZ = 'rof-TZ',
  ru = 'ru',
  ru_MD = 'ru-MD',
  ru_RU = 'ru-RU',
  ru_UA = 'ru-UA',
  rw = 'rw',
  rw_RW = 'rw-RW',
  rwk = 'rwk',
  rwk_TZ = 'rwk-TZ',
  saq = 'saq',
  saq_KE = 'saq-KE',
  sbp = 'sbp',
  sbp_TZ = 'sbp-TZ',
  seh = 'seh',
  seh_MZ = 'seh-MZ',
  ses = 'ses',
  ses_ML = 'ses-ML',
  sg = 'sg',
  sg_CF = 'sg-CF',
  shi = 'shi',
  shi_Latn = 'shi-Latn',
  shi_Latn_MA = 'shi-Latn-MA',
  shi_Tfng = 'shi-Tfng',
  shi_Tfng_MA = 'shi-Tfng-MA',
  si = 'si',
  si_LK = 'si-LK',
  sk = 'sk',
  sk_SK = 'sk-SK',
  sl = 'sl',
  sl_SI = 'sl-SI',
  sn = 'sn',
  sn_ZW = 'sn-ZW',
  so = 'so',
  so_DJ = 'so-DJ',
  so_ET = 'so-ET',
  so_KE = 'so-KE',
  so_SO = 'so-SO',
  sq = 'sq',
  sq_AL = 'sq-AL',
  sr = 'sr',
  sr_Cyrl = 'sr-Cyrl',
  sr_Cyrl_BA = 'sr-Cyrl-BA',
  sr_Cyrl_ME = 'sr-Cyrl-ME',
  sr_Cyrl_RS = 'sr-Cyrl-RS',
  sr_Latn = 'sr-Latn',
  sr_Latn_BA = 'sr-Latn-BA',
  sr_Latn_ME = 'sr-Latn-ME',
  sr_Latn_RS = 'sr-Latn-RS',
  sv = 'sv',
  sv_FI = 'sv-FI',
  sv_SE = 'sv-SE',
  sw = 'sw',
  sw_KE = 'sw-KE',
  sw_TZ = 'sw-TZ',
  swc = 'swc',
  swc_CD = 'swc-CD',
  ta = 'ta',
  ta_IN = 'ta-IN',
  ta_LK = 'ta-LK',
  te = 'te',
  te_IN = 'te-IN',
  teo = 'teo',
  teo_KE = 'teo-KE',
  teo_UG = 'teo-UG',
  th = 'th',
  th_TH = 'th-TH',
  ti = 'ti',
  ti_ER = 'ti-ER',
  ti_ET = 'ti-ET',
  to = 'to',
  to_TO = 'to-TO',
  tr = 'tr',
  tr_TR = 'tr-TR',
  twq = 'twq',
  twq_NE = 'twq-NE',
  tzm = 'tzm',
  tzm_Latn = 'tzm-Latn',
  tzm_Latn_MA = 'tzm-Latn-MA',
  uk = 'uk',
  uk_UA = 'uk-UA',
  ur = 'ur',
  ur_IN = 'ur-IN',
  ur_PK = 'ur-PK',
  uz = 'uz',
  uz_Arab = 'uz-Arab',
  uz_Arab_AF = 'uz-Arab-AF',
  uz_Cyrl = 'uz-Cyrl',
  uz_Cyrl_UZ = 'uz-Cyrl-UZ',
  uz_Latn = 'uz-Latn',
  uz_Latn_UZ = 'uz-Latn-UZ',
  vai = 'vai',
  vai_Latn = 'vai-Latn',
  vai_Latn_LR = 'vai-Latn-LR',
  vai_Vaii = 'vai-Vaii',
  vai_Vaii_LR = 'vai-Vaii-LR',
  vi = 'vi',
  vi_VN = 'vi-VN',
  vun = 'vun',
  vun_TZ = 'vun-TZ',
  xog = 'xog',
  xog_UG = 'xog-UG',
  yav = 'yav',
  yav_CM = 'yav-CM',
  yo = 'yo',
  yo_NG = 'yo-NG',
  zh = 'zh',
  zh_Hans = 'zh-Hans',
  zh_Hans_AE = 'zh-Hans-AE',
  zh_Hans_AR = 'zh-Hans-AR',
  zh_Hans_AT = 'zh-Hans-AT',
  zh_Hans_AU = 'zh-Hans-AU',
  zh_Hans_BE = 'zh-Hans-BE',
  zh_Hans_BG = 'zh-Hans-BG',
  zh_Hans_BH = 'zh-Hans-BH',
  zh_Hans_BR = 'zh-Hans-BR',
  zh_Hans_BW = 'zh-Hans-BW',
  zh_Hans_CA = 'zh-Hans-CA',
  zh_Hans_CH = 'zh-Hans-CH',
  zh_Hans_CL = 'zh-Hans-CL',
  zh_Hans_CN = 'zh-Hans-CN',
  zh_Hans_CO = 'zh-Hans-CO',
  zh_Hans_CR = 'zh-Hans-CR',
  zh_Hans_CY = 'zh-Hans-CY',
  zh_Hans_CZ = 'zh-Hans-CZ',
  zh_Hans_DE = 'zh-Hans-DE',
  zh_Hans_DK = 'zh-Hans-DK',
  zh_Hans_DO = 'zh-Hans-DO',
  zh_Hans_EE = 'zh-Hans-EE',
  zh_Hans_EG = 'zh-Hans-EG',
  zh_Hans_ES = 'zh-Hans-ES',
  zh_Hans_FI = 'zh-Hans-FI',
  zh_Hans_GB = 'zh-Hans-GB',
  zh_Hans_GE = 'zh-Hans-GE',
  zh_Hans_GF = 'zh-Hans-GF',
  zh_Hans_GH = 'zh-Hans-GH',
  zh_Hans_GI = 'zh-Hans-GI',
  zh_Hans_GR = 'zh-Hans-GR',
  zh_Hans_HK = 'zh-Hans-HK',
  zh_Hans_HR = 'zh-Hans-HR',
  zh_Hans_HU = 'zh-Hans-HU',
  zh_Hans_ID = 'zh-Hans-ID',
  zh_Hans_IE = 'zh-Hans-IE',
  zh_Hans_IL = 'zh-Hans-IL',
  zh_Hans_IN = 'zh-Hans-IN',
  zh_Hans_IS = 'zh-Hans-IS',
  zh_Hans_IT = 'zh-Hans-IT',
  zh_Hans_JO = 'zh-Hans-JO',
  zh_Hans_JP = 'zh-Hans-JP',
  zh_Hans_KR = 'zh-Hans-KR',
  zh_Hans_KW = 'zh-Hans-KW',
  zh_Hans_KY = 'zh-Hans-KY',
  zh_Hans_LB = 'zh-Hans-LB',
  zh_Hans_LI = 'zh-Hans-LI',
  zh_Hans_LT = 'zh-Hans-LT',
  zh_Hans_LU = 'zh-Hans-LU',
  zh_Hans_LV = 'zh-Hans-LV',
  zh_Hans_MA = 'zh-Hans-MA',
  zh_Hans_MC = 'zh-Hans-MC',
  zh_Hans_MG = 'zh-Hans-MG',
  zh_Hans_MK = 'zh-Hans-MK',
  zh_Hans_MO = 'zh-Hans-MO',
  zh_Hans_MT = 'zh-Hans-MT',
  zh_Hans_MU = 'zh-Hans-MU',
  zh_Hans_MX = 'zh-Hans-MX',
  zh_Hans_MY = 'zh-Hans-MY',
  zh_Hans_NA = 'zh-Hans-NA',
  zh_Hans_NL = 'zh-Hans-NL',
  zh_Hans_NO = 'zh-Hans-NO',
  zh_Hans_NZ = 'zh-Hans-NZ',
  zh_Hans_OM = 'zh-Hans-OM',
  zh_Hans_PE = 'zh-Hans-PE',
  zh_Hans_PH = 'zh-Hans-PH',
  zh_Hans_PK = 'zh-Hans-PK',
  zh_Hans_PL = 'zh-Hans-PL',
  zh_Hans_PR = 'zh-Hans-PR',
  zh_Hans_PT = 'zh-Hans-PT',
  zh_Hans_PY = 'zh-Hans-PY',
  zh_Hans_QA = 'zh-Hans-QA',
  zh_Hans_RO = 'zh-Hans-RO',
  zh_Hans_RU = 'zh-Hans-RU',
  zh_Hans_SA = 'zh-Hans-SA',
  zh_Hans_SE = 'zh-Hans-SE',
  zh_Hans_SG = 'zh-Hans-SG',
  zh_Hans_SK = 'zh-Hans-SK',
  zh_Hans_SI = 'zh-Hans-SI',
  zh_Hans_TH = 'zh-Hans-TH',
  zh_Hans_TR = 'zh-Hans-TR',
  zh_Hans_TW = 'zh-Hans-TW',
  zh_Hans_UA = 'zh-Hans-UA',
  zh_Hans_US = 'zh-Hans-US',
  zh_Hans_UY = 'zh-Hans-UY',
  zh_Hans_VE = 'zh-Hans-VE',
  zh_Hans_VN = 'zh-Hans-VN',
  zh_Hans_ZA = 'zh-Hans-ZA',
  zh_Hant = 'zh-Hant',
  zh_Hant_AE = 'zh-Hant-AE',
  zh_Hant_AR = 'zh-Hant-AR',
  zh_Hant_AT = 'zh-Hant-AT',
  zh_Hant_AU = 'zh-Hant-AU',
  zh_Hant_BE = 'zh-Hant-BE',
  zh_Hant_BG = 'zh-Hant-BG',
  zh_Hant_BH = 'zh-Hant-BH',
  zh_Hant_BR = 'zh-Hant-BR',
  zh_Hant_BW = 'zh-Hant-BW',
  zh_Hant_CA = 'zh-Hant-CA',
  zh_Hant_CH = 'zh-Hant-CH',
  zh_Hant_CL = 'zh-Hant-CL',
  zh_Hant_CN = 'zh-Hant-CN',
  zh_Hant_CO = 'zh-Hant-CO',
  zh_Hant_CR = 'zh-Hant-CR',
  zh_Hant_CY = 'zh-Hant-CY',
  zh_Hant_CZ = 'zh-Hant-CZ',
  zh_Hant_DE = 'zh-Hant-DE',
  zh_Hant_DK = 'zh-Hant-DK',
  zh_Hant_DO = 'zh-Hant-DO',
  zh_Hant_EE = 'zh-Hant-EE',
  zh_Hant_EG = 'zh-Hant-EG',
  zh_Hant_ES = 'zh-Hant-ES',
  zh_Hant_FI = 'zh-Hant-FI',
  zh_Hant_GB = 'zh-Hant-GB',
  zh_Hant_GE = 'zh-Hant-GE',
  zh_Hant_GF = 'zh-Hant-GF',
  zh_Hant_GH = 'zh-Hant-GH',
  zh_Hant_GI = 'zh-Hant-GI',
  zh_Hant_GR = 'zh-Hant-GR',
  zh_Hant_HK = 'zh-Hant-HK',
  zh_Hant_HR = 'zh-Hant-HR',
  zh_Hant_HU = 'zh-Hant-HU',
  zh_Hant_ID = 'zh-Hant-ID',
  zh_Hant_IE = 'zh-Hant-IE',
  zh_Hant_IL = 'zh-Hant-IL',
  zh_Hant_IN = 'zh-Hant-IN',
  zh_Hant_IS = 'zh-Hant-IS',
  zh_Hant_IT = 'zh-Hant-IT',
  zh_Hant_JO = 'zh-Hant-JO',
  zh_Hant_JP = 'zh-Hant-JP',
  zh_Hant_KR = 'zh-Hant-KR',
  zh_Hant_KW = 'zh-Hant-KW',
  zh_Hant_KY = 'zh-Hant-KY',
  zh_Hant_LB = 'zh-Hant-LB',
  zh_Hant_LI = 'zh-Hant-LI',
  zh_Hant_LT = 'zh-Hant-LT',
  zh_Hant_LU = 'zh-Hant-LU',
  zh_Hant_LV = 'zh-Hant-LV',
  zh_Hant_MA = 'zh-Hant-MA',
  zh_Hant_MC = 'zh-Hant-MC',
  zh_Hant_MG = 'zh-Hant-MG',
  zh_Hant_MK = 'zh-Hant-MK',
  zh_Hant_MO = 'zh-Hant-MO',
  zh_Hant_MT = 'zh-Hant-MT',
  zh_Hant_MU = 'zh-Hant-MU',
  zh_Hant_MX = 'zh-Hant-MX',
  zh_Hant_MY = 'zh-Hant-MY',
  zh_Hant_NA = 'zh-Hant-NA',
  zh_Hant_NL = 'zh-Hant-NL',
  zh_Hant_NO = 'zh-Hant-NO',
  zh_Hant_NZ = 'zh-Hant-NZ',
  zh_Hant_OM = 'zh-Hant-OM',
  zh_Hant_PE = 'zh-Hant-PE',
  zh_Hant_PH = 'zh-Hant-PH',
  zh_Hant_PK = 'zh-Hant-PK',
  zh_Hant_PL = 'zh-Hant-PL',
  zh_Hant_PR = 'zh-Hant-PR',
  zh_Hant_PT = 'zh-Hant-PT',
  zh_Hant_PY = 'zh-Hant-PY',
  zh_Hant_QA = 'zh-Hant-QA',
  zh_Hant_RO = 'zh-Hant-RO',
  zh_Hant_RU = 'zh-Hant-RU',
  zh_Hant_SA = 'zh-Hant-SA',
  zh_Hant_SE = 'zh-Hant-SE',
  zh_Hant_SG = 'zh-Hant-SG',
  zh_Hant_SK = 'zh-Hant-SK',
  zh_Hant_SI = 'zh-Hant-SI',
  zh_Hant_TH = 'zh-Hant-TH',
  zh_Hant_TR = 'zh-Hant-TR',
  zh_Hant_TW = 'zh-Hant-TW',
  zh_Hant_UA = 'zh-Hant-UA',
  zh_Hant_US = 'zh-Hant-US',
  zh_Hant_UY = 'zh-Hant-UY',
  zh_Hant_VE = 'zh-Hant-VE',
  zh_Hant_VN = 'zh-Hant-VN',
  zh_Hant_ZA = 'zh-Hant-ZA',
  zu = 'zu',
  zu_ZA = 'zu-ZA',
}

export enum LocaleNames {
  af = 'Afrikaans',
  af_NA = 'Afrikaans (Namibia)',
  af_ZA = 'Afrikaans (South Africa)',
  agq = 'Aghem',
  agq_CM = 'Aghem (Cameroon)',
  ak = 'Akan',
  ak_GH = 'Akan (Ghana)',
  am = 'Amharic',
  am_ET = 'Amharic (Ethiopia)',
  ar = 'Arabic',
  ar_001 = 'Arabic (World)',
  ar_AE = 'Arabic (United Arab Emirates)',
  ar_BH = 'Arabic (Bahrain)',
  ar_DZ = 'Arabic (Algeria)',
  ar_EG = 'Arabic (Egypt)',
  ar_IQ = 'Arabic (Iraq)',
  ar_JO = 'Arabic (Jordan)',
  ar_KW = 'Arabic (Kuwait)',
  ar_LB = 'Arabic (Lebanon)',
  ar_LY = 'Arabic (Libya)',
  ar_MA = 'Arabic (Morocco)',
  ar_OM = 'Arabic (Oman)',
  ar_QA = 'Arabic (Qatar)',
  ar_SA = 'Arabic (Saudi Arabia)',
  ar_SD = 'Arabic (Sudan)',
  ar_SY = 'Arabic (Syria)',
  ar_TN = 'Arabic (Tunisia)',
  ar_YE = 'Arabic (Yemen)',
  as = 'Assamese',
  as_IN = 'Assamese (India)',
  asa = 'Asu',
  asa_TZ = 'Asu (Tanzania)',
  az = 'Azerbaijani',
  az_Cyrl = 'Azerbaijani (Cyrillic)',
  az_Cyrl_AZ = 'Azerbaijani (Cyrillic, Azerbaijan)',
  az_Latn = 'Azerbaijani (Latin)',
  az_Latn_AZ = 'Azerbaijani (Latin, Azerbaijan)',
  bas = 'Basa',
  bas_CM = 'Basa (Cameroon)',
  be = 'Belarusian',
  be_BY = 'Belarusian (Belarus)',
  bem = 'Bemba',
  bem_ZM = 'Bemba (Zambia)',
  bez = 'Bena',
  bez_TZ = 'Bena (Tanzania)',
  bg = 'Bulgarian',
  bg_BG = 'Bulgarian (Bulgaria)',
  bm = 'Bambara',
  bm_ML = 'Bambara (Mali)',
  bn = 'Bengali',
  bn_BD = 'Bengali (Bangladesh)',
  bn_IN = 'Bengali (India)',
  bo = 'Tibetan',
  bo_CN = 'Tibetan (China)',
  bo_IN = 'Tibetan (India)',
  br = 'Breton',
  br_FR = 'Breton (France)',
  brx = 'Bodo',
  brx_IN = 'Bodo (India)',
  bs = 'Bosnian',
  bs_BA = 'Bosnian (Bosnia and Herzegovina)',
  ca = 'Catalan',
  ca_ES = 'Catalan (Spain)',
  cgg = 'Chiga',
  cgg_UG = 'Chiga (Uganda)',
  chr = 'Cherokee',
  chr_US = 'Cherokee (United States)',
  cs = 'Czech',
  cs_CZ = 'Czech (Czech Republic)',
  cy = 'Welsh',
  cy_GB = 'Welsh (United Kingdom)',
  da = 'Danish',
  da_DK = 'Danish (Denmark)',
  dav = 'Taita',
  dav_KE = 'Taita (Kenya)',
  de = 'German',
  de_AT = 'German (Austria)',
  de_BE = 'German (Belgium)',
  de_CH = 'German (Switzerland)',
  de_DE = 'German (Germany)',
  de_LI = 'German (Liechtenstein)',
  de_LU = 'German (Luxembourg)',
  dje = 'Zarma',
  dje_NE = 'Zarma (Niger)',
  dua = 'Duala',
  dua_CM = 'Duala (Cameroon)',
  dyo = 'Jola-Fonyi',
  dyo_SN = 'Jola-Fonyi (Senegal)',
  ebu = 'Embu',
  ebu_KE = 'Embu (Kenya)',
  ee = 'Ewe',
  ee_GH = 'Ewe (Ghana)',
  ee_TG = 'Ewe (Togo)',
  el = 'Greek',
  el_CY = 'Greek (Cyprus)',
  el_GR = 'Greek (Greece)',
  en = 'English',
  en_AE = 'English (United Arab Emirates)',
  en_AR = 'English (Argentina)',
  en_AS = 'English (American Samoa)',
  en_AT = 'English (Austria)',
  en_AU = 'English (Australia)',
  en_BB = 'English (Barbados)',
  en_BE = 'English (Belgium)',
  en_BG = 'English (Bulgaria)',
  en_BH = 'English (Bahrain)',
  en_BM = 'English (Bermuda)',
  en_BR = 'English (Brazil)',
  en_BW = 'English (Botswana)',
  en_BZ = 'English (Belize)',
  en_CA = 'English (Canada)',
  en_CH = 'English (Switzerland)',
  en_CL = 'English (Chile)',
  en_CN = 'English (China)',
  en_CO = 'English (Colombia)',
  en_CR = 'English (Costa Rica)',
  en_CY = 'English (Cyprus)',
  en_CZ = 'English (Czech Republic)',
  en_DE = 'English (Germany)',
  en_DK = 'English (Denmark)',
  en_DO = 'English (Dominican Republic)',
  en_EE = 'English (Estonia)',
  en_EG = 'English (Egypt)',
  en_ES = 'English (Spain)',
  en_FI = 'English (Finland)',
  en_GB = 'English (United Kingdom)',
  en_GE = 'English (Georgia)',
  en_GF = 'English (French Guiana)',
  en_GH = 'English (Ghana)',
  en_GI = 'English (Gibraltar)',
  en_GR = 'English (Greece)',
  en_GU = 'English (Guam)',
  en_GY = 'English (Guyana)',
  en_HK = 'English (Hong Kong)',
  en_HR = 'English (Croatia)',
  en_HU = 'English (Hungary)',
  en_ID = 'English (Indonesia)',
  en_IE = 'English (Ireland)',
  en_IL = 'English (Israel)',
  en_IN = 'English (India)',
  en_IS = 'English (Iceland)',
  en_IT = 'English (Italy)',
  en_JM = 'English (Jamaica)',
  en_JO = 'English (Jordan)',
  en_JP = 'English (Japan)',
  en_KR = 'English (South Korea)',
  en_KW = 'English (Kuwait)',
  en_KY = 'English (Cayman Islands)',
  en_LB = 'English (Lebanon)',
  en_LI = 'English (Liechtenstein)',
  en_LT = 'English (Lithuania)',
  en_LU = 'English (Luxembourg)',
  en_LV = 'English (Latvia)',
  en_MA = 'English (Morocco)',
  en_MC = 'English (Monaco)',
  en_MG = 'English (Madagascar)',
  en_MH = 'English (Marshall Islands)',
  en_MK = 'English (North Macedonia)',
  en_MO = 'English (Macau)',
  en_MP = 'English (Northern Mariana Islands)',
  en_MT = 'English (Malta)',
  en_MU = 'English (Mauritius)',
  en_MX = 'English (Mexico)',
  en_MY = 'English (Malaysia)',
  en_NA = 'English (Namibia)',
  en_NL = 'English (Netherlands)',
  en_NO = 'English (Norway)',
  en_NZ = 'English (New Zealand)',
  en_OM = 'English (Oman)',
  en_PE = 'English (Peru)',
  en_PH = 'English (Philippines)',
  en_PK = 'English (Pakistan)',
  en_PL = 'English (Poland)',
  en_PR = 'English (Puerto Rico)',
  en_PT = 'English (Portugal)',
  en_PY = 'English (Paraguay)',
  en_QA = 'English (Qatar)',
  en_RO = 'English (Romania)',
  en_RU = 'English (Russia)',
  en_SA = 'English (Saudi Arabia)',
  en_SE = 'English (Sweden)',
  en_SG = 'English (Singapore)',
  en_SK = 'English (Slovakia)',
  en_SI = 'English (Slovenia)',
  en_TH = 'English (Thailand)',
  en_TR = 'English (Turkey)',
  en_TT = 'English (Trinidad and Tobago)',
  en_TW = 'English (Taiwan)',
  en_UA = 'English (Ukraine)',
  en_UM = 'English (U.S. Minor Outlying Islands)',
  en_US = 'English (United States)',
  en_US_POSIX = 'English (United States, POSIX)',
  en_UY = 'English (Uruguay)',
  en_VE = 'English (Venezuela)',
  en_VI = 'English (U.S. Virgin Islands)',
  en_VN = 'English (Vietnam)',
  en_ZA = 'English (South Africa)',
  en_ZW = 'English (Zimbabwe)',
  eo = 'Esperanto',
  es = 'Spanish',
  es_419 = 'Spanish (Latin America)',
  es_AR = 'Spanish (Argentina)',
  es_BO = 'Spanish (Bolivia)',
  es_CL = 'Spanish (Chile)',
  es_CO = 'Spanish (Colombia)',
  es_CR = 'Spanish (Costa Rica)',
  es_DO = 'Spanish (Dominican Republic)',
  es_EC = 'Spanish (Ecuador)',
  es_ES = 'Spanish (Spain)',
  es_GQ = 'Spanish (Equatorial Guinea)',
  es_GT = 'Spanish (Guatemala)',
  es_HN = 'Spanish (Honduras)',
  es_MX = 'Spanish (Mexico)',
  es_NI = 'Spanish (Nicaragua)',
  es_PA = 'Spanish (Panama)',
  es_PE = 'Spanish (Peru)',
  es_PR = 'Spanish (Puerto Rico)',
  es_PY = 'Spanish (Paraguay)',
  es_SV = 'Spanish (El Salvador)',
  es_US = 'Spanish (United States)',
  es_UY = 'Spanish (Uruguay)',
  es_VE = 'Spanish (Venezuela)',
  et = 'Estonian',
  et_EE = 'Estonian (Estonia)',
  eu = 'Basque',
  eu_ES = 'Basque (Spain)',
  ewo = 'Ewondo',
  ewo_CM = 'Ewondo (Cameroon)',
  fa = 'Persian',
  fa_AF = 'Persian (Afghanistan)',
  fa_IR = 'Persian (Iran)',
  ff = 'Fulah',
  ff_SN = 'Fulah (Senegal)',
  fi = 'Finnish',
  fi_FI = 'Finnish (Finland)',
  fil = 'Filipino',
  fil_PH = 'Filipino (Philippines)',
  fo = 'Faroese',
  fo_FO = 'Faroese (Faroe Islands)',
  fr = 'French',
  fr_BE = 'French (Belgium)',
  fr_BF = 'French (Burkina Faso)',
  fr_BI = 'French (Burundi)',
  fr_BJ = 'French (Benin)',
  fr_BL = 'French (Saint Barthélemy)',
  fr_CA = 'French (Canada)',
  fr_CD = 'French (Democratic Republic of the Congo)',
  fr_CF = 'French (Central African Republic)',
  fr_CG = 'French (Republic of the Congo)',
  fr_CH = 'French (Switzerland)',
  fr_CI = 'French (Ivory Coast)',
  fr_CM = 'French (Cameroon)',
  fr_DJ = 'French (Djibouti)',
  fr_FR = 'French (France)',
  fr_GA = 'French (Gabon)',
  fr_GF = 'French (French Guiana)',
  fr_GN = 'French (Guinea)',
  fr_GP = 'French (Guadeloupe)',
  fr_GQ = 'French (Equatorial Guinea)',
  fr_KM = 'French (Comoros)',
  fr_LU = 'French (Luxembourg)',
  fr_MC = 'French (Monaco)',
  fr_MF = 'French (Saint Martin)',
  fr_MG = 'French (Madagascar)',
  fr_ML = 'French (Mali)',
  fr_MQ = 'French (Martinique)',
  fr_NE = 'French (Niger)',
  fr_RE = 'French (Réunion)',
  fr_RW = 'French (Rwanda)',
  fr_SN = 'French (Senegal)',
  fr_TD = 'French (Chad)',
  fr_TG = 'French (Togo)',
  fr_YT = 'French (Mayotte)',
  ga = 'Irish',
  ga_IE = 'Irish (Ireland)',
  gl = 'Galician',
  gl_ES = 'Galician (Spain)',
  gsw = 'Swiss German',
  gsw_CH = 'Swiss German (Switzerland)',
  gu = 'Gujarati',
  gu_IN = 'Gujarati (India)',
  guz = 'Gusii',
  guz_KE = 'Gusii (Kenya)',
  gv = 'Manx',
  gv_GB = 'Manx (United Kingdom)',
  ha = 'Hausa',
  ha_Latn = 'Hausa (Latin script)',
  ha_Latn_GH = 'Hausa (Latin script, Ghana)',
  ha_Latn_NE = 'Hausa (Latin script, Niger)',
  ha_Latn_NG = 'Hausa (Latin script, Nigeria)',
  haw = 'Hawaiian',
  haw_US = 'Hawaiian (United States)',
  he = 'Hebrew',
  he_IL = 'Hebrew (Israel)',
  hi = 'Hindi',
  hi_IN = 'Hindi (India)',
  hr = 'Croatian',
  hr_HR = 'Croatian (Croatia)',
  hu = 'Hungarian',
  hu_HU = 'Hungarian (Hungary)',
  hy = 'Armenian',
  hy_AM = 'Armenian (Armenia)',
  id = 'Indonesian',
  id_ID = 'Indonesian (Indonesia)',
  ig = 'Igbo',
  ig_NG = 'Igbo (Nigeria)',
  ii = 'Sichuan Yi',
  ii_CN = 'Sichuan Yi (China)',
  is = 'Icelandic',
  is_IS = 'Icelandic (Iceland)',
  it = 'Italian',
  it_CH = 'Italian (Switzerland)',
  it_IT = 'Italian (Italy)',
  ja = 'Japanese',
  ja_JP = 'Japanese (Japan)',
  jmc = 'Machi',
  jmc_TZ = 'Machi (Tanzania)',
  ka = 'Georgian',
  ka_GE = 'Georgian (Georgia)',
  kab = 'Kabyle',
  kab_DZ = 'Kabyle (Algeria)',
  kam = 'Kamba',
  kam_KE = 'Kamba (Kenya)',
  kde = 'Makonde',
  kde_TZ = 'Makonde (Tanzania)',
  kea = 'Kabuverdianu',
  kea_CV = 'Kabuverdianu (Cape Verde)',
  khq = 'Koyra Chiini',
  khq_ML = 'Koyra Chiini (Mali)',
  ki = 'Kikuyu',
  ki_KE = 'Kikuyu (Kenya)',
  kk = 'Kazakh',
  kk_Cyrl = 'Kazakh (Cyrillic)',
  kk_Cyrl_KZ = 'Kazakh (Cyrillic, Kazakhstan)',
  kl = 'Kalaallisut',
  kl_GL = 'Kalaallisut (Greenland)',
  kln = 'Kalenjin',
  kln_KE = 'Kalenjin (Kenya)',
  km = 'Khmer',
  km_KH = 'Khmer (Cambodia)',
  kn = 'Kannada',
  kn_IN = 'Kannada (India)',
  ko = 'Korean',
  ko_KR = 'Korean (South Korea)',
  kok = 'Konkani',
  kok_IN = 'Konkani (India)',
  ksb = 'Shambala',
  ksb_TZ = 'Shambala (Tanzania)',
  ksf = 'Bafia',
  ksf_CM = 'Bafia (Cameroon)',
  kw = 'Cornish',
  kw_GB = 'Cornish (United Kingdom)',
  lag = 'Langi',
  lag_TZ = 'Langi (Tanzania)',
  lg = 'Ganda',
  lg_UG = 'Ganda (Uganda)',
  ln = 'Lingala',
  ln_CD = 'Lingala (Congo)',
  ln_CG = 'Lingala (Republic of the Congo)',
  lt = 'Lithuanian',
  lt_LT = 'Lithuanian (Lithuania)',
  lu = 'Luba-Katanga',
  lu_CD = 'Luba-Katanga (Congo)',
  luo = 'Luo',
  luo_KE = 'Luo (Kenya)',
  luy = 'Luyia',
  luy_KE = 'Luyia (Kenya)',
  lv = 'Latvian',
  lv_LV = 'Latvian (Latvia)',
  mas = 'Masai',
  mas_KE = 'Masai (Kenya)',
  mas_TZ = 'Masai (Tanzania)',
  mer = 'Meru',
  mer_KE = 'Meru (Kenya)',
  mfe = 'Mauritian Creole',
  mfe_MU = 'Mauritian Creole (Mauritius)',
  mg = 'Malagasy',
  mg_MG = 'Malagasy (Madagascar)',
  mgh = 'Makhuwa-Meetto',
  mgh_MZ = 'Makhuwa-Meetto (Mozambique)',
  mk = 'Macedonian',
  mk_MK = 'Macedonian (North Macedonia)',
  ml = 'Malayalam',
  ml_IN = 'Malayalam (India)',
  mr = 'Marathi',
  mr_IN = 'Marathi (India)',
  ms = 'Malay',
  ms_BN = 'Malay (Brunei)',
  ms_MY = 'Malay (Malaysia)',
  mt = 'Maltese',
  mt_MT = 'Maltese (Malta)',
  mua = 'Mundang',
  mua_CM = 'Mundang (Cameroon)',
  my = 'Burmese',
  my_MM = 'Burmese (Myanmar)',
  naq = 'Nama',
  naq_NA = 'Nama (Namibia)',
  nb = 'Norwegian Bokmål',
  nb_NO = 'Norwegian Bokmål (Norway)',
  nd = 'North Ndebele',
  nd_ZW = 'North Ndebele (Zimbabwe)',
  ne = 'Nepali',
  ne_IN = 'Nepali (India)',
  ne_NP = 'Nepali (Nepal)',
  nl = 'Dutch',
  nl_AW = 'Dutch (Aruba)',
  nl_BE = 'Dutch (Belgium)',
  nl_CW = 'Dutch (Curaçao)',
  nl_NL = 'Dutch (Netherlands)',
  nl_SX = 'Dutch (Sint Maarten)',
  nmg = 'Kwasio',
  nmg_CM = 'Kwasio (Cameroon)',
  nn = 'Norwegian Nynorsk',
  nn_NO = 'Norwegian Nynorsk (Norway)',
  nus = 'Nuer',
  nus_SD = 'Nuer (Sudan)',
  nyn = 'Nyankole',
  nyn_UG = 'Nyankole (Uganda)',
  om = 'Oromo',
  om_ET = 'Oromo (Ethiopia)',
  om_KE = 'Oromo (Kenya)',
  or = 'Odia',
  or_IN = 'Odia (India)',
  pa = 'Punjabi',
  pa_Arab = 'Punjabi (Arabic script)',
  pa_Arab_PK = 'Punjabi (Arabic script, Pakistan)',
  pa_Guru = 'Punjabi (Gurmukhi script)',
  pa_Guru_IN = 'Punjabi (Gurmukhi script, India)',
  pl = 'Polish',
  pl_PL = 'Polish (Poland)',
  ps = 'Pashto',
  ps_AF = 'Pashto (Afghanistan)',
  pt = 'Portuguese',
  pt_AO = 'Portuguese (Angola)',
  pt_BR = 'Portuguese (Brazil)',
  pt_GW = 'Portuguese (Guinea-Bissau)',
  pt_MZ = 'Portuguese (Mozambique)',
  pt_PT = 'Portuguese (Portugal)',
  pt_ST = 'Portuguese (São Tomé and Príncipe)',
  rm = 'Romansh',
  rm_CH = 'Romansh (Switzerland)',
  rn = 'Rundi',
  rn_BI = 'Rundi (Burundi)',
  ro = 'Romanian',
  ro_MD = 'Romanian (Moldova)',
  ro_RO = 'Romanian (Romania)',
  rof = 'Rombo',
  rof_TZ = 'Rombo (Tanzania)',
  ru = 'Russian',
  ru_MD = 'Russian (Moldova)',
  ru_RU = 'Russian (Russia)',
  ru_UA = 'Russian (Ukraine)',
  rw = 'Kinyarwanda',
  rw_RW = 'Kinyarwanda (Rwanda)',
  rwk = 'Rwa',
  rwk_TZ = 'Rwa (Tanzania)',
  saq = 'Samburu',
  saq_KE = 'Samburu (Kenya)',
  sbp = 'Sangu',
  sbp_TZ = 'Sangu (Tanzania)',
  seh = 'Sena',
  seh_MZ = 'Sena (Mozambique)',
  ses = 'Susu',
  ses_ML = 'Susu (Mali)',
  sg = 'Sango',
  sg_CF = 'Sango (Central African Republic)',
  shi = 'Tachelhit',
  shi_Latn = 'Tachelhit (Latin)',
  shi_Latn_MA = 'Tachelhit (Latin, Morocco)',
  shi_Tfng = 'Tachelhit (Tifinagh)',
  shi_Tfng_MA = 'Tachelhit (Tifinagh, Morocco)',
  si = 'Sinhala',
  si_LK = 'Sinhala (Sri Lanka)',
  sk = 'Slovak',
  sk_SK = 'Slovak (Slovakia)',
  sl = 'Slovene',
  sl_SI = 'Slovene (Slovenia)',
  sn = 'Shona',
  sn_ZW = 'Shona (Zimbabwe)',
  so = 'Somali',
  so_DJ = 'Somali (Djibouti)',
  so_ET = 'Somali (Ethiopia)',
  so_KE = 'Somali (Kenya)',
  so_SO = 'Somali (Somalia)',
  sq = 'Albanian',
  sq_AL = 'Albanian (Albania)',
  sr = 'Serbian',
  sr_Cyrl = 'Serbian (Cyrillic)',
  sr_Cyrl_BA = 'Serbian (Cyrillic, Bosnia and Herzegovina)',
  sr_Cyrl_ME = 'Serbian (Cyrillic, Montenegro)',
  sr_Cyrl_RS = 'Serbian (Cyrillic, Serbia)',
  sr_Latn = 'Serbian (Latin)',
  sr_Latn_BA = 'Serbian (Latin, Bosnia and Herzegovina)',
  sr_Latn_ME = 'Serbian (Latin, Montenegro)',
  sr_Latn_RS = 'Serbian (Latin, Serbia)',
  sv = 'Swedish',
  sv_FI = 'Swedish (Finland)',
  sv_SE = 'Swedish (Sweden)',
  sw = 'Swahili',
  sw_KE = 'Swahili (Kenya)',
  sw_TZ = 'Swahili (Tanzania)',
  swc = 'Congo Swahili',
  swc_CD = 'Congo Swahili (Congo)',
  ta = 'Tamil',
  ta_IN = 'Tamil (India)',
  ta_LK = 'Tamil (Sri Lanka)',
  te = 'Telugu',
  te_IN = 'Telugu (India)',
  teo = 'Teso',
  teo_KE = 'Teso (Kenya)',
  teo_UG = 'Teso (Uganda)',
  th = 'Thai',
  th_TH = 'Thai (Thailand)',
  ti = 'Tigrinya',
  ti_ER = 'Tigrinya (Eritrea)',
  ti_ET = 'Tigrinya (Ethiopia)',
  to = 'Tongan',
  to_TO = 'Tongan (Tonga)',
  tr = 'Turkish',
  tr_TR = 'Turkish (Turkey)',
  twq = 'Tasawaq',
  twq_NE = 'Tasawaq (Niger)',
  tzm = 'Central Atlas Tamazight',
  tzm_Latn = 'Central Atlas Tamazight (Latin)',
  tzm_Latn_MA = 'Central Atlas Tamazight (Latin, Morocco)',
  uk = 'Ukrainian',
  uk_UA = 'Ukrainian (Ukraine)',
  ur = 'Urdu',
  ur_IN = 'Urdu (India)',
  ur_PK = 'Urdu (Pakistan)',
  uz = 'Uzbek',
  uz_Arab = 'Uzbek (Arabic)',
  uz_Arab_AF = 'Uzbek (Arabic, Afghanistan)',
  uz_Cyrl = 'Uzbek (Cyrillic)',
  uz_Cyrl_UZ = 'Uzbek (Cyrillic, Uzbekistan)',
  uz_Latn = 'Uzbek (Latin)',
  uz_Latn_UZ = 'Uzbek (Latin, Uzbekistan)',
  vai = 'Vai',
  vai_Latn = 'Vai (Latin)',
  vai_Latn_LR = 'Vai (Latin, Liberia)',
  vai_Vaii = 'Vai (Vaii)',
  vai_Vaii_LR = 'Vai (Vaii, Liberia)',
  vi = 'Vietnamese',
  vi_VN = 'Vietnamese (Vietnam)',
  vun = 'Vunjo',
  vun_TZ = 'Vunjo (Tanzania)',
  xog = 'Soga',
  xog_UG = 'Soga (Uganda)',
  yav = 'Yav',
  yav_CM = 'Yav (Cameroon)',
  yo = 'Yoruba',
  yo_NG = 'Yoruba (Nigeria)',
  zh = 'Chinese',
  zh_Hans = 'Chinese (Simplified)',
  zh_Hans_AE = 'Chinese (Simplified, United Arab Emirates)',
  zh_Hans_AR = 'Chinese (Simplified, Argentina)',
  zh_Hans_AT = 'Chinese (Simplified, Austria)',
  zh_Hans_AU = 'Chinese (Simplified, Australia)',
  zh_Hans_BE = 'Chinese (Simplified, Belgium)',
  zh_Hans_BG = 'Chinese (Simplified, Bulgaria)',
  zh_Hans_BH = 'Chinese (Simplified, Bahrain)',
  zh_Hans_BR = 'Chinese (Simplified, Brazil)',
  zh_Hans_BW = 'Chinese (Simplified, Botswana)',
  zh_Hans_CA = 'Chinese (Simplified, Canada)',
  zh_Hans_CH = 'Chinese (Simplified, Switzerland)',
  zh_Hans_CL = 'Chinese (Simplified, Chile)',
  zh_Hans_CN = 'Chinese (Simplified, China)',
  zh_Hans_CO = 'Chinese (Simplified, Colombia)',
  zh_Hans_CR = 'Chinese (Simplified, Costa Rica)',
  zh_Hans_CY = 'Chinese (Simplified, Cyprus)',
  zh_Hans_CZ = 'Chinese (Simplified, Czech Republic)',
  zh_Hans_DE = 'Chinese (Simplified, Germany)',
  zh_Hans_DK = 'Chinese (Simplified, Denmark)',
  zh_Hans_DO = 'Chinese (Simplified, Dominican Republic)',
  zh_Hans_EE = 'Chinese (Simplified, Estonia)',
  zh_Hans_EG = 'Chinese (Simplified, Egypt)',
  zh_Hans_ES = 'Chinese (Simplified, Spain)',
  zh_Hans_FI = 'Chinese (Simplified, Finland)',
  zh_Hans_GB = 'Chinese (Simplified, United Kingdom)',
  zh_Hans_GE = 'Chinese (Simplified, Georgia)',
  zh_Hans_GF = 'Chinese (Simplified, French Guiana)',
  zh_Hans_GH = 'Chinese (Simplified, Ghana)',
  zh_Hans_GI = 'Chinese (Simplified, Gibraltar)',
  zh_Hans_GR = 'Chinese (Simplified, Greece)',
  zh_Hans_HK = 'Chinese (Simplified, Hong Kong)',
  zh_Hans_HR = 'Chinese (Simplified, Croatia)',
  zh_Hans_HU = 'Chinese (Simplified, Hungary)',
  zh_Hans_ID = 'Chinese (Simplified, Indonesia)',
  zh_Hans_IE = 'Chinese (Simplified, Ireland)',
  zh_Hans_IL = 'Chinese (Simplified, Israel)',
  zh_Hans_IN = 'Chinese (Simplified, India)',
  zh_Hans_IS = 'Chinese (Simplified, Iceland)',
  zh_Hans_IT = 'Chinese (Simplified, Italy)',
  zh_Hans_JO = 'Chinese (Simplified, Jordan)',
  zh_Hans_JP = 'Chinese (Simplified, Japan)',
  zh_Hans_KR = 'Chinese (Simplified, South Korea)',
  zh_Hans_KW = 'Chinese (Simplified, Kuwait)',
  zh_Hans_KY = 'Chinese (Simplified, Cayman Islands)',
  zh_Hans_LB = 'Chinese (Simplified, Lebanon)',
  zh_Hans_LI = 'Chinese (Simplified, Liechtenstein)',
  zh_Hans_LT = 'Chinese (Simplified, Lithuania)',
  zh_Hans_LU = 'Chinese (Simplified, Luxembourg)',
  zh_Hans_LV = 'Chinese (Simplified, Latvia)',
  zh_Hans_MA = 'Chinese (Simplified, Morocco)',
  zh_Hans_MC = 'Chinese (Simplified, Monaco)',
  zh_Hans_MG = 'Chinese (Simplified, Madagascar)',
  zh_Hans_MK = 'Chinese (Simplified, North Macedonia)',
  zh_Hans_MO = 'Chinese (Simplified, Macau)',
  zh_Hans_MT = 'Chinese (Simplified, Malta)',
  zh_Hans_MU = 'Chinese (Simplified, Mauritius)',
  zh_Hans_MX = 'Chinese (Simplified, Mexico)',
  zh_Hans_MY = 'Chinese (Simplified, Malaysia)',
  zh_Hans_NA = 'Chinese (Simplified, Namibia)',
  zh_Hans_NL = 'Chinese (Simplified, Netherlands)',
  zh_Hans_NO = 'Chinese (Simplified, Norway)',
  zh_Hans_NZ = 'Chinese (Simplified, New Zealand)',
  zh_Hans_OM = 'Chinese (Simplified, Oman)',
  zh_Hans_PE = 'Chinese (Simplified, Peru)',
  zh_Hans_PH = 'Chinese (Simplified, Philippines)',
  zh_Hans_PK = 'Chinese (Simplified, Pakistan)',
  zh_Hans_PL = 'Chinese (Simplified, Poland)',
  zh_Hans_PR = 'Chinese (Simplified, Puerto Rico)',
  zh_Hans_PT = 'Chinese (Simplified, Portugal)',
  zh_Hans_PY = 'Chinese (Simplified, Paraguay)',
  zh_Hans_QA = 'Chinese (Simplified, Qatar)',
  zh_Hans_RO = 'Chinese (Simplified, Romania)',
  zh_Hans_RU = 'Chinese (Simplified, Russia)',
  zh_Hans_SA = 'Chinese (Simplified, Saudi Arabia)',
  zh_Hans_SE = 'Chinese (Simplified, Sweden)',
  zh_Hans_SG = 'Chinese (Simplified, Singapore)',
  zh_Hans_SK = 'Chinese (Simplified, Slovakia)',
  zh_Hans_SI = 'Chinese (Simplified, Slovenia)',
  zh_Hans_TH = 'Chinese (Simplified, Thailand)',
  zh_Hans_TR = 'Chinese (Simplified, Turkey)',
  zh_Hans_TW = 'Chinese (Simplified, Taiwan)',
  zh_Hans_UA = 'Chinese (Simplified, Ukraine)',
  zh_Hans_US = 'Chinese (Simplified, United States)',
  zh_Hans_UY = 'Chinese (Simplified, Uruguay)',
  zh_Hans_VE = 'Chinese (Simplified, Venezuela)',
  zh_Hans_VN = 'Chinese (Simplified, Vietnam)',
  zh_Hans_ZA = 'Chinese (Simplified, South Africa)',
  zh_Hant = 'Chinese (Traditional)',
  zh_Hant_AE = 'Chinese (Traditional, United Arab Emirates)',
  zh_Hant_AR = 'Chinese (Traditional, Argentina)',
  zh_Hant_AT = 'Chinese (Traditional, Austria)',
  zh_Hant_AU = 'Chinese (Traditional, Australia)',
  zh_Hant_BE = 'Chinese (Traditional, Belgium)',
  zh_Hant_BG = 'Chinese (Traditional, Bulgaria)',
  zh_Hant_BH = 'Chinese (Traditional, Bahrain)',
  zh_Hant_BR = 'Chinese (Traditional, Brazil)',
  zh_Hant_BW = 'Chinese (Traditional, Botswana)',
  zh_Hant_CA = 'Chinese (Traditional, Canada)',
  zh_Hant_CH = 'Chinese (Traditional, Switzerland)',
  zh_Hant_CL = 'Chinese (Traditional, Chile)',
  zh_Hant_CN = 'Chinese (Traditional, China)',
  zh_Hant_CO = 'Chinese (Traditional, Colombia)',
  zh_Hant_CR = 'Chinese (Traditional, Costa Rica)',
  zh_Hant_CY = 'Chinese (Traditional, Cyprus)',
  zh_Hant_CZ = 'Chinese (Traditional, Czech Republic)',
  zh_Hant_DE = 'Chinese (Traditional, Germany)',
  zh_Hant_DK = 'Chinese (Traditional, Denmark)',
  zh_Hant_DO = 'Chinese (Traditional, Dominican Republic)',
  zh_Hant_EE = 'Chinese (Traditional, Estonia)',
  zh_Hant_EG = 'Chinese (Traditional, Egypt)',
  zh_Hant_ES = 'Chinese (Traditional, Spain)',
  zh_Hant_FI = 'Chinese (Traditional, Finland)',
  zh_Hant_GB = 'Chinese (Traditional, United Kingdom)',
  zh_Hant_GE = 'Chinese (Traditional, Georgia)',
  zh_Hant_GF = 'Chinese (Traditional, French Guiana)',
  zh_Hant_GH = 'Chinese (Traditional, Ghana)',
  zh_Hant_GI = 'Chinese (Traditional, Gibraltar)',
  zh_Hant_GR = 'Chinese (Traditional, Greece)',
  zh_Hant_HK = 'Chinese (Traditional, Hong Kong)',
  zh_Hant_HR = 'Chinese (Traditional, Croatia)',
  zh_Hant_HU = 'Chinese (Traditional, Hungary)',
  zh_Hant_ID = 'Chinese (Traditional, Indonesia)',
  zh_Hant_IE = 'Chinese (Traditional, Ireland)',
  zh_Hant_IL = 'Chinese (Traditional, Israel)',
  zh_Hant_IN = 'Chinese (Traditional, India)',
  zh_Hant_IS = 'Chinese (Traditional, Iceland)',
  zh_Hant_IT = 'Chinese (Traditional, Italy)',
  zh_Hant_JO = 'Chinese (Traditional, Jordan)',
  zh_Hant_JP = 'Chinese (Traditional, Japan)',
  zh_Hant_KR = 'Chinese (Traditional, South Korea)',
  zh_Hant_KW = 'Chinese (Traditional, Kuwait)',
  zh_Hant_KY = 'Chinese (Traditional, Cayman Islands)',
  zh_Hant_LB = 'Chinese (Traditional, Lebanon)',
  zh_Hant_LI = 'Chinese (Traditional, Liechtenstein)',
  zh_Hant_LT = 'Chinese (Traditional, Lithuania)',
  zh_Hant_LU = 'Chinese (Traditional, Luxembourg)',
  zh_Hant_LV = 'Chinese (Traditional, Latvia)',
  zh_Hant_MA = 'Chinese (Traditional, Morocco)',
  zh_Hant_MC = 'Chinese (Traditional, Monaco)',
  zh_Hant_MG = 'Chinese (Traditional, Madagascar)',
  zh_Hant_MK = 'Chinese (Traditional, North Macedonia)',
  zh_Hant_MO = 'Chinese (Traditional, Macau)',
  zh_Hant_MT = 'Chinese (Traditional, Malta)',
  zh_Hant_MU = 'Chinese (Traditional, Mauritius)',
  zh_Hant_MX = 'Chinese (Traditional, Mexico)',
  zh_Hant_MY = 'Chinese (Traditional, Malaysia)',
  zh_Hant_NA = 'Chinese (Traditional, Namibia)',
  zh_Hant_NL = 'Chinese (Traditional, Netherlands)',
  zh_Hant_NO = 'Chinese (Traditional, Norway)',
  zh_Hant_NZ = 'Chinese (Traditional, New Zealand)',
  zh_Hant_OM = 'Chinese (Traditional, Oman)',
  zh_Hant_PE = 'Chinese (Traditional, Peru)',
  zh_Hant_PH = 'Chinese (Traditional, Philippines)',
  zh_Hant_PK = 'Chinese (Traditional, Pakistan)',
  zh_Hant_PL = 'Chinese (Traditional, Poland)',
  zh_Hant_PR = 'Chinese (Traditional, Puerto Rico)',
  zh_Hant_PT = 'Chinese (Traditional, Portugal)',
  zh_Hant_PY = 'Chinese (Traditional, Paraguay)',
  zh_Hant_QA = 'Chinese (Traditional, Qatar)',
  zh_Hant_RO = 'Chinese (Traditional, Romania)',
  zh_Hant_RU = 'Chinese (Traditional, Russia)',
  zh_Hant_SA = 'Chinese (Traditional, Saudi Arabia)',
  zh_Hant_SE = 'Chinese (Traditional, Sweden)',
  zh_Hant_SG = 'Chinese (Traditional, Singapore)',
  zh_Hant_SK = 'Chinese (Traditional, Slovakia)',
  zh_Hant_SI = 'Chinese (Traditional, Slovenia)',
  zh_Hant_TH = 'Chinese (Traditional, Thailand)',
  zh_Hant_TR = 'Chinese (Traditional, Turkey)',
  zh_Hant_TW = 'Chinese (Traditional, Taiwan)',
  zh_Hant_UA = 'Chinese (Traditional, Ukraine)',
  zh_Hant_US = 'Chinese (Traditional, United States)',
  zh_Hant_UY = 'Chinese (Traditional, Uruguay)',
  zh_Hant_VE = 'Chinese (Traditional, Venezuela)',
  zh_Hant_VN = 'Chinese (Traditional, Vietnam)',
  zh_Hant_ZA = 'Chinese (Traditional, South Africa)',
  zu = 'Zulu',
  zu_ZA = 'Zulu (South Africa)',
}
