import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'finxone-web-frontend-pin-boxes',
  templateUrl: './pin-boxes.component.html',
  styleUrl: './pin-boxes.component.scss',
})
export class PinBoxesComponent implements AfterViewInit {
  @ViewChild('pinInput', { static: false }) pinInput!: ElementRef<HTMLInputElement>;
  @Input() public pin = '';
  @Input() public error = '';
  @Input() public maxLength = 6;
  @Output() pinBoxes = new EventEmitter();
  @Output() pinChange = new EventEmitter<string>();
  public pinBoxesFocused = true;

  ngAfterViewInit() {
    // Focus on the input element
    this.pinInput.nativeElement.focus();
  }

  public handleDesktopPinBoxes(e: Event) {
    const input: string = (e.target as HTMLInputElement).value.replace(/[^0-9]/g, '');
    if (input.length <= this.maxLength) {
      this.pin = input;
      this.pinChange.emit(this.pin);
      this.error = '';
      this.pinBoxes.emit();
    }
  }

  public pinBoxesFocus() {
    this.pinInput.nativeElement.focus();
    this.pinBoxesFocused = true;
  }

  public pinBoxesBlur() {
    this.pinBoxesFocused = false;
  }
}
