import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { WorkflowFormsService } from './workflow-forms.service';

@Injectable({
  providedIn: 'root',
})
export class WorkflowFormGuard implements CanDeactivate<any> {
  constructor(
    private workflowForms: WorkflowFormsService,
    private dialog: MatDialog,
    private router: Router,
  ) {}

  canDeactivate(
    component: any,
    currentRoute: any,
    currentState: any,
    nextState?: any,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Check if there are any forms registered on the current page
    const validation = this.workflowForms.validateCurrentPageForms();

    if (!validation.isValid) {
      // Mark all forms as touched to show validation errors
      this.workflowForms.markCurrentPageFormsTouched();

      //   // Show dialog to user
      //   this.showValidationDialog(validation.invalidForms);
      return false;
    }

    return true;
  }
}
