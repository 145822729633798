// Utility function to convert string to Uint8Array
const str2ab = (str: string) => {
  const buf = new Uint8Array(str.length);
  for (let i = 0; i < str.length; i++) {
    buf[i] = str.charCodeAt(i);
  }
  return buf;
};

// Utility function to convert ArrayBuffer to hex string
const buf2hex = (buffer: ArrayBuffer) => {
  return Array.from(new Uint8Array(buffer))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
};

// Generate random bytes for nonce
const generateNonce = () => {
  const array = new Uint8Array(16);
  crypto.getRandomValues(array);
  return buf2hex(array);
};

// Generate HMAC signature using Web Crypto API
const generateSignature = async (message: string, secretKey: string) => {
  const encoder = new TextEncoder();
  const keyData = encoder.encode(secretKey);

  // Import the secret key
  const cryptoKey = await crypto.subtle.importKey(
    'raw',
    keyData,
    {
      name: 'HMAC',
      hash: { name: 'SHA-256' },
    },
    false,
    ['sign'],
  );

  // Sign the message
  const signature = await crypto.subtle.sign('HMAC', cryptoKey, encoder.encode(message));

  return buf2hex(signature);
};

// Main function to generate headers
export async function generateRequestHeaders(secretKey: string) {
  const nonce = generateNonce();
  const timestamp = Date.now().toString();

  // Generate signature from nonce and timestamp
  const signature = await generateSignature(`${nonce}:${timestamp}`, secretKey);

  return {
    'x-nonce': nonce,
    'x-timestamp': timestamp,
    'x-signature': signature,
  };
}
