import { Locale } from '@finxone-platform/shared/enums';

export type AlertDetails = {
  name: string;
  type: string;
  title: string;
  description: string;
  alertCodes?: string;
};

export type AlertData = {
  key: string;
  value: {
    functionality: string;
    subgroup: string;
    name: string;
    title: string;
    description: string;
  };
};

export enum AlertsFunctionality {
  ONBOARDING = 'onboarding',
  VERIFICATION = 'verification',
  PROFILE = 'profile',
  PAYMENTS = 'payments',
  BENEFICIARY = 'beneficiary',
  MANAGE_USERS = 'manage_users',
  BULK_PAYMENTS = 'bulk_payments',
  FEE_MANAGEMENT = 'fee_management',
  CARDS = 'cards',
  OTHERS = 'others',
}

export enum AlertsOnboardingSubGroup {
  VERIFY_EMAIL = 'verify_email',
  VERIFY_PHONE = 'verify_phone',
  ONBOARD_INDIVIDUAL = 'onboard_individual',
}

export enum AlertsVerificationSubGroup {
  VERIFY_EMAIL = 'verify_email',
  VERIFY_PHONE = 'verify_phone',
  UPDATE_PROFILE = 'update_profile',
}

export enum AlertsProfileSubGroup {
  NOTIFICATIONS = 'notification_settings',
  BANK_STATEMENT = 'bank_statements',
  VERIFY_EMAIL = 'verify_email',
  VERIFY_PHONE = 'verify_phone',
  UPDATE_PROFILE = 'update_profile',
  CHANGE_PASSWORD = 'change_password',
}

export enum AlertsIndividualFeeChargeActionSubGroup {
  FEE_RETRY = 'fee_retry',
  FEE_REVERT = 'fee_revert',
}

export enum AlertsPaymentActionSubGroup {
  PAYMENT_REQUEST = 'payment_request',
  TRANSFER_PAYMENT = 'transfer_payment',
}

export enum AlertsCardActionSubGroup {
  CARD_MANAGEMENT = 'card_management',
}

export type AlertGroupToCodeType = {
  [functionality: string]: {
    [subGroup: string]: {
      [errorCode: string]: AlertDetails;
    };
  };
};

export type AlertsType = {
  [locale: string]: {
    alerts: { [errorCode: string]: AlertDetails };
  };
};

export type AlertsMappingType = {
  [locale: string]: {
    alerts: {
      [functionality: string]: {
        [subGroup: string]: string[];
      };
    };
  };
};
export interface AlertResponse {
  id?: string;
  key: string;
  locale: Locale;
  value: { [errorCode: string]: AlertDetails };
  isDefault: boolean;
  created_at?: string;
  updated_at?: string;
}

export enum AlertTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

export enum DismissalTypeMobile {
  CLOSE_AFTER = 'close-after',
  SWIPE_TO_DISMISS = 'swipe-to-dismiss',
  STICKY = 'sticky',
}

export enum DismissalTypeDesktop {
  CLOSE_AFTER = 'close-after',
  CLOSE_ON_HOVER = 'close-on-hover',
  STICKY = 'sticky',
}

export enum AlertPositions {
  UPPER_LEFT = 'upper-left',
  UPPER_CENTER = 'upper-centre',
  UPPER_RIGHT = 'upper-right',
  LOWER_LEFT = 'lower-left',
  LOWER_CENTER = 'lower-centre',
  LOWER_RIGHT = 'lower-right',
  CENTER = 'centre',
  CENTER_RIGHT = 'centre-right',
  CENTER_LEFT = 'centre-left',
}

export enum PushNotificationsSubGroup {
  TRANSACTIONS_ALERTS = 'transactions_alerts',
  BENEFICIARY_ALERTS = 'beneficiary_alerts',
  PROFILE_ALERTS = 'profile_alerts',
}

export type PushNotificationMappingType = {
  [locale: string]: {
    push: {
      [category: string]: string[];
    };
  };
};
