<div class="pin-input-container">
  <input
    #pinInput
    type="number"
    pattern="[0-9]*"
    inputmode="numeric"
    [value]="pin"
    (input)="handleDesktopPinBoxes($event)"
    class="pin-hidden-input"
    autocomplete="off">

  <div class="pin-boxes" (click)="pinBoxesFocus()" (clickOutside)="pinBoxesBlur()" [ngClass]="{'focused' : pinBoxesFocused}">
    <div
      *ngFor="let _ of [].constructor(maxLength); let i = index"
      class="pin-box is-center inputField"
      [class.filled]="pin.length > i"
      [class.active]="pin.length === i"
      [class.error]="error"
    >
      <div *ngIf="pin[i]" class="pin-dot"></div>
      <div *ngIf="pin.length === i" class="pin-cursor"></div>
    </div>
  </div>
</div>