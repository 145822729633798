import { FormControl } from '@angular/forms';
import { Locale } from '@finxone-platform/shared/enums';
import { PaymentProvider } from './cards.type';
import { OnboardingConfigFlowTypes, OrgCheckTypes } from './common.type';
import { BorderRadiusClasses, TypographyKeys } from './default-constants/global-styling.enum';
import {
  AlertPositions,
  AlertTypes,
  DismissalTypeDesktop,
  DismissalTypeMobile,
} from './default-constants/toaster-alerts.type';
import { PACKS } from './default-constants/workflow-constants.type';
import {
  BaseCssConfig,
  ComponentLabel,
  GridPosition,
  NavBar,
  SupportedDevice,
  SystemZones,
  WidgetLayout,
  ZONETYPEOPTION,
  customDataType,
} from './workflow.type';
import { ZoneConfiguration } from './workflow.v2.type';

export enum WidgetNames {
  // Nested UI Widgets
  DROPDOWN = 'dropdown',
  BUTTON = 'button',
  CARD = 'card',
  // Normal
  SIGNUP = 'signup',
  VERIFICATION = 'verification',
  LOGIN = 'login',
  ACCOUNT_BALANCE_WIDGET = 'account_balance_widget',
  ACCOUNT_MONTHLY_IN_AND_OUT_WIDGET = 'account_monthly_in_and_out_widget',
  PAYMENT = 'payment',
  PAYMENT_BUTTON = 'payment-button',
  RECENT_TRANSACTION_LIST_WIDGET = 'recent_transaction_list_widget',
  PAYMENT_TRANSFER = 'payment-transfer',
  PROFILE = 'profile',
  PROFILE_PAGE = 'profile-page',
  VERIFICATION_PAGE = 'consent-verification',
  BURNDOWN_CHART = 'burndown_chart',
  USER_PROFILE_WIDGET = 'user_profile_widget',
  USER_PRIVACY_WIDGET = 'user_privacy_widget',
  QUESTION_WIDGET = 'question_widget',
  BIRTHDAY_WIDGET = 'birthday_widget',
  NAME_WIDGET = 'name_widget',
  PHONE_WIDGET = 'phone_widget',
  MOT_VERIFICATION_WIDGET = 'mot_verification_widget',
  EMAIL_WIDGET = 'email_widget',
  ADDRESS_WIDGET = 'address_widget',
  COMPANY_HOUSE_CHECK_WIDGET = 'companyhousecheck_widget',
  COMPANY_HOUSE_CHECK = 'company_house_check',
  MOT_VERIFICATION = 'mot_verification',
  PASSWORD_WIDGET = 'password_widget',
  IMAGE_WIDGET = 'image_widget',
  LINE_WIDGET = 'line_widget',
  CAROUSEL_WIDGET = 'carousel_widget',
  LOGO_WIDGET = 'logo_widget',
  CUSTOM_QUESTION_WIDGET = 'custom_question_widget',
  TEXT_WIDGET = 'text_widget',
  EMBEDDED_TEXT_WIDGET = 'embedded_text_widget',
  H1_WIDGET = 'h1_widget',
  PROFILE_WIDGET_PAGE = 'profile-widget-page',
  IFRAME_WIDGET = 'iframe_widget',
  USER_MANAGEMENT_WIDGET = 'list',
  TEXT_ITEM_WIDGET = 'text_item_widget',
  ACCOUNT_NUMBER = 'account_number',
  SORT_CODE = 'sort_code',
  IBAN_NUMBER = 'iban_number',
  QUICK_LINKS_WIDGET = 'quick_links_widget',
  RESEND_WIDGET = 'resend_widget',
  CARD_MANAGEMENT = 'card_management',
  FREEZE_CARD = 'freeze_card',
  REPLACE_CARD = 'replace_card',
  CHANGE_PIN = 'change_pin',
  LOST_CARD = 'lost_card',
  VIEW_PIN = 'view_pin',
  VIEW_CONFIRM_DETAIL = 'view_confirm_detail',
  EDIT_CONFIRM_DETAIL = 'edit_confirm_detail',
  VIEW_CARD_DELIVERY_STATUS = 'view_card_delivery_status',
  TRACK_CARD_DELIVERY_STATUS = 'track_card_delivery_status',
  LANDING_WIDGETS_PAGE = 'landing_widgets_page',
  LANDING_ACCOUNTS_LISTING = 'landing_accounts_listing',
  ACCOUNTS_CONTEXT_DROPDOWN = 'landing_accounts_context_dropdown',
  CARD_ACTIVATION = 'card_activation',
  CARD_MANAGEMENT_WIDGETS_PAGE = 'card_management_widgets_page',
  IMAGE_LIBRARY = 'image_library',
  COUNTRY_WIDGET = 'country_widget',
  PROPERTY_NAME_WIDGET = 'property_name_widget',
  PROPERTY_NUMBER_WIDGET = 'property_number_widget',
  STREET_WIDGET = 'street_widget',
  POST_CODE_WIDGET = 'post_code_widget',
  PHONE_VERIFY_WIDGET = 'phone_verify_widget',
  EMAIL_VERIFY_WIDGET = 'email_verify_widget',
  CTA_BUTTON_WIDGET = 'cta_button_widget',
  PHONE_VERIFICATION_POPUP = 'phone_verification_popup',
  EMAIL_VERIFICATION_POPUP = 'email_verification_popup',
  PROFILE_DETAIL_VERIFICATION = 'profile_detail_verification',
  VERIFICATION_DETAIL_VERIFICATION = 'user_profile_confirmation',
  CREDENTIAL_VERIFICATION = 'cred_verification',
  CARD_BACK_SIDE = 'card_back_side',
  SUMSUB_WIDGET = 'sumsub_widget',
  SIMPLE_BALANCE_WIDGET = 'simple_balance_widget',
  SUMSUB_SECTION = 'third_party_KYC',
  ACTIVATE_CARD = 'activate-card',
  ACTIVATE_CARD_OTP = 'activate-card-otp',
  USER_DETAIL_WIDGET = 'user_detail_widget',
  ACCOUNT_DETAIL_WIDGET = 'account_detail_widget',
  CONTACT_DETAIL_WIDGET = 'contact_detail_widget',
  IMPORT_USER_WIDGET = 'import_user_widget',
  ACCOUNT_LIST_WIDGET = 'account_list_widget',
  PASSWORD_SETTING_WIDGET = 'password_setting_widget',
  ADDRESS_WIDGET_USER = 'address_user_widget',
  BENEFICIARY_CAROUSEL = 'beneficiary_carousel',
  MULTI_ROLE_WIDGET = 'multi_role_widget',
  PROGRESSBAR_WIDGET = 'progressbar_widget',
  BACK_BUTTON_WIDGET = 'back_button_widget',
  FORWARD_BUTTON_WIDGET = 'forward_button_widget',
  ADD_BENEFICIARY_WIDGET = 'add_beneficiary_widget',
  ADD_BENEFICIARY_FASTER_PAYMENTS = 'add_beneficiary_faster_payments_widget',
  LOGOUT = 'logout',
  INTEGRATION_WIDGET = 'integration_widget',
  TRIP_LISTING_WIDGET = 'trip_listing_widget',
  TRIP_ADD_SELECT_WIDGET = 'trip_add_select_widget',
  DESKTOP_TRIP_LISTING_WIDGET = 'desktop_trip_listing_widget',
  TRIP_INFORMATION_WIDGET = 'trip_information_widget',
  SWITCH_NAVIGATOR_QUICK_LINK = 'switch_navigator_quick_link',
  BENEFICIARY_SELECTION_WIDGET = 'beneficiary_selection_widget',
  ACCOUNT_SELECTION_LIST_WIDGET = 'account_selection_list_widget',
  MULTI_ICON_QUICK_LINK_WIDGET = 'multi_icon_quick_link_widget',
  CONFIRM_BENEFICIARY_DETAILS = 'confirm_beneficiary_details',
  LOTTIE_ANIMATION = 'lottie_animation',
  USE_SUGGESTED_BENEFICARY_NAME = 'use_suggested_beneficiary_name',
  CURRENCY_INPUT_FIELD_WIDGET = 'currency_input_field_widget',
  PAYMENT_REF_WIDGET = 'payment_ref_widget',
  MANDATORY_CTA_BUTTON = 'mandatory_cta_button',
  ENTITY_MANDATORY_CTA_BUTTON = 'entity_mandatory_cta_button',
  PROFILE_PICTURE_WIDGET = 'profile_picture_widget',
  BIOMETRICS_AUTHENTICATION_WIDGET = 'biometrics_authentication_widget',
  NOTIFICATION_BELL_WIDGET = 'notification_bell_widget',
  NOTIFICATION_WIDGET = 'notification_widget',
  QUESTION_SUMMARY_WIDGET = 'question_summary_widget',
  NOTIFICATION_LIST_WIDGET = 'notification_list_widget',
  EXCHANGE_CURRENCY_WIDGET = 'exchange_currency_widget',
  EXCHANGE_QUOTE_WIDGET = 'exchange_quote_widget',
  PASSWORD_CHANGE = 'password_change',
  TRIP_INFORMATION_EXIT_WIDGET = 'trip_information_exit_widget',
  PASSWORD_CHANGE_SUCCESS = 'password_change_success',
  PASSWORD_FORGOT = 'password_forgot',
  PASSWORD_ENTER_EMAIL = 'password_enter_email',
  PASSWORD_UPDATE = 'password_update',
  CHECK_EMAIL = 'check_email',
  INTL_BENEFICIARY_ADDRESS = 'intl_beneficiary_address',
  INTL_BENEFICIARY_ADDRESS_WIDGET = 'intl_beneficiary_address_widget',
  INTL_BENEFICIARY_ADDED = 'intl_beneficiary_added',
  CURRENCY_ACCOUNTS_LISTING_WIDGET = 'currency_accounts_listing_widget',
  ADD_CURRENCY_ACCOUNT_WIDGET = 'add_currency_account_widget',
  EXCHANGE_QUOTE_SUMMARY = 'exchange_quote_summary',
  ADD_FACILITY_STEP_1 = 'add_facility_step_1',
  INTERNATIONAL_CURRENCY_COUNTRY = 'international_currency_country',
  FILE_UPLOAD_WIDGET = 'file_upload_widget',
  INTERNATIONAL_BENEFICIARY_CREDENTIALS_WIDGET = 'international_beneficiary_credentials_widget',
  INTERNATIONAL_BENEFICIARY_CREDENTIALS = 'international_beneficiary_credentials',
  VERIFICATION_ORGANISATION_COMPANY_NAME = 'verification_organisation_company_name',
  LINK_FACILITY = 'link_facility',
  DOWNLOAD_DOCUMENT_WIDGET = 'download_document_widget',
  RANGE_CALENDAR_WIDGET = 'range_calendar_widget',
  EDIT_ADDRESS_WIDGET = 'edit_address_widget',
  OTP_VERIFICATION = 'otp_verification_widget',
  OTP_VERIFICATION_INTERNATIONAL_PAYMENTS = 'otp_verification_international_payments',
  OTP_VERIFICATION_APPROVER_PAYMENTS = 'otp_verification_approver_payments',
  OTP_VERIFICATION_PAYMENT_APPROVAL = 'otp_verification_payment_approval',
  FORM_SUBMISSION_BUTTON = 'form_submission_button_widget',
  PAYMENT_REASON_WIDGET = 'payment_reason_widget',
  DESKTOP_USER_LISTING_WIDGET = 'desktop_user_listing_widget',
  DESKTOP_MANAGE_USER_LISTING_WIDGET = 'desktop_manage_user_listing_widget',
  ASSIGN_PERSONA_ROLE = 'assign-persona-role',
  ADD_EMPLOYEE_STEP_1_WIDGETS = 'add-employee-step-1-widgets',
  MANAGE_USER_STEP_01_WIDGET = 'manage-user-step-01-widget',
  USER_NAME_WIDGET = 'user_name_widget',
  ADD_EMPLOYEE_STEP_2_WIDGETS = 'add-employee-step-2-widgets',
  MANAGE_USER_STEP_02_WIDGET = 'manage-user-step-02-widget',
  MANAGE_USER_STEP_03_WIDGET = 'manage-user-step-03-widget',
  MANAGE_USER_ASSIGN_PERSONA = 'manage-user-assign-persona',
  VIEW_USER_ASSIGN_PERSONA = 'view-user-assign-persona',
  PROFILE_PICTURE_UPLOAD_WIDGET = 'profile_picture_upload_widget',
  PROFILE_PICTURE_DISPLAY = 'profile_picture_display',
  EDIT_BENEFICIARY_INTERNATIONAL_CURRENCY_COUNTRY = 'edit_beneficiary_international_currency_country',
  EDIT_INTERNATIONAL_BENEFICIARY_CREDENTIALS_WIDGET = 'edit_international_beneficiary_credentials_widget',
  EDIT_INTL_BENEFICIARY_ADDRESS = 'edit_intl_beneficiary_address',
  DESKTOP_EVENT_LINKED_LISTING_WIDGET = 'desktop_event_linked_listing_widget',
  LINK_TRIP_WIDGET = 'link_trip_widget',
  EDIT_BENEFICIARY_WIDGET = 'edit_beneficiary_widget',
  DESKTOP_GUEST_USER_LISTING_WIDGET = 'desktop_guest_user_listing_widget',
  DESKTOP_EMPLOYEE_USER_LISTING_WIDGET = 'desktop_employee_user_listing_widget',
  BULK__PAYMENT_ACCOUNTS_LISTING_WIDGET = 'bulk_payment_accounts_listing_widget',
  DESKTOP_BULK_PAYMENT_LISTING_WIDGET = 'desktop_bulk_payment_listing_widget',
  DESKTOP_BULK_PAYMENT_HISTORY_LISTING_WIDGET = 'desktop_bulk_payment_history_listing_widget',
  OTP_VERIFICATION_BULK_PAYMENTS = 'otp_verification_bulk_payments',
  CONDITIONAL_WIDGET = 'conditional_widget',
  CONDITIONAL_WIDGET_IF_ELSE_CONFIG = 'conditional_widget_config',
  ONBOARDING_BACK_NEXT_BUTTON = 'onboarding_back_next_button',
  UPLOAD_FILE_WITH_POPUP_WIDGET = 'upload_file_with_popup',
  ADD_EDIT_FEE = 'add_edit_fee',
  EU_COUNTRY_DROPDOWN = 'eu_country_dropdown',
  DOCUMENT_PREVIEW = 'document_preview',
  FEE_CLIENT_LISTING_WIDGET = 'fee_client_listing_widget',
  ADD_FEE_GROUP_WIDGET = 'add_fee_group_widget',
  FEE_GROUPS_LISTING = 'group_listing_widget',
  FEE_CLIENT_ACCOUNT_LISTING_WIDGET = 'fee_client_accounts_listing_widget',
  ASSIGN_GROUP_WIDGET = 'assign_group_widget',
  FEE_CONFIGURATION_WIDGET = 'fee_configuration_widget',
  COPY_SHARE_CONTENT = 'copy_share_content',
  TRANSACTION_BUTTON = 'transaction-button',
  TRANSACTION_LIST = 'transaction_list',
  CLIENT_FEE_LISTING_WIDGET = 'client_fee_listing_widget',
  CARDS_WIDGET = 'cards_widget',
  FREEZE_CARD_TOGGLE_SWITCH = 'freeze-card-toggle-switch-widget',
  SECTION = 'section-widget',
  TWO_COLUMN = 'two-column-widget',
  TAB = 'tab-widget',
  SPACER = 'spacer-widget',
  MAIN = 'main-widget',
  DOCUMENT_UPLOAD_PREVIEW = 'document-upload-preview',
  CARD_SENSITIVE_DETAILS = 'card-sensitive-details',
  REVENIR_TRANSACTION_LIST = 'revenir_transaction_list',
  REVENIR_TRANSACTION_ITEM_LIST = 'revenir_transaction_item_list',
  REVENIR_TRANSACTION_DOCUMENT = 'revenir-transaction-document',
  REVENIR_ARCHIVED_TRIPS = 'revenir_archived_trips',
  REVENIR_ARCHIVED_TRIPS_COUNTRY_NAME_CONTENT = 'revenir_archived_trips_country_name_content',
  REVENIR_ARCHIVED_TRIP_CARD_CONTENT = 'revenir_archived_trip_card_content',
  REVENIR_ARCHIVED_TRIP_STATUS_CONTENT = 'revenir_archived_trip_status_content',
  REVENIR_BARCODE_IMAGE_VIEWER_COMPONENT = 'revenir-barcode-image-viewer-component',
  UPLOAD_BOARDING_PASS_WIDGET = 'upload_boarding_pass',
  REVIEW_UPLOAD_BOARDING_PASS = 'review-upload-boarding-pass',
  REVENIR_TRIP_RECLAIM_STATUS_WIDGET = 'trip-reclaim-status-widget',
  REVENIR_TRIP_RECLAIM_STATUS_CONTENT = 'trip-reclaim-status-content',
  UPLOAD_PASSPORT_WIDGET = 'upload-passport-widget',
  REVENIR_TRANSACTION_LIST_FIRST_CHARACTER = 'revenir_transaction_list_first_character',
  WIDGET_NESTED_BUTTON = 'widget-nested-button',
  TIMER_BUTTON = 'timer-button',
  TERMS_CONFIRMATION = 'terms-confirmation',
  DESKTOP_PAYMENT_REQUEST_PENDING_APPROVED_LISTING_WIDGET = 'desktop-payment-request-pending-approved-listing-widget',
  SECONDARY_IDENTIFIER = 'secondary-identifier',
  CARD_PIN = 'card-pin',
}

export enum SectionNames {
  MANAGE_USER_PROPERTY_SECTION = 'manage_user_property_section',
  ADD_USER_WIDGET_SECTION = 'detail',
  MANAGE_USER_WIDGET_SECTION = 'list',
}
export enum RoleStatus {
  NEW = 'new',
  UPDATE = 'update',
  DELETE = 'deleted',
  NOUPDATE = 'no-updates',
}

export enum ActiveDevice {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}

export enum DeviceZoomLevel {
  MOBILE = 11,
  TABLET = 9,
  DESKTOP = 7,
}

export enum WidgetTypes {
  SIGNUP = 'signup_widget',
  QUESTION = 'question_widget',
  CUSTOM_QUESTION = 'custom_question_widget',
}

export enum VerificationStatus {
  PASS = 'Pass',
  FAILED = 'Failed',
}

export enum ProjectConfigOnboardingFlow {
  BUSINESS_FIRST = 'business_first',
  PERSONAL_FIRST = 'personal_first',
}

export enum BaseCssProperties {
  V_ALIGN_DISPLAY_BLOCK = 'vertical-align-display-block',
  MARGIN = 'margin',
  PADDING = 'padding',
  COLOR = 'color',
  TEXTALIGN = 'text-align',
  BORDER = 'border',
  BACKGROUND_COLOR = 'background-color',
  V_ALIGN = 'vertical-align',
  DISABLE_BACKGROUND_COLOR = 'disable-background-color',
  DISABLE_COLOR = 'disable-color',
  OBJECT_FIT = 'object-fit',
  FONT_SIZE = 'font-size',
  TEXT_BOLD = 'text-bold',
  TEXT_ITALIC = 'text-italic',
  TEXT_UNDERLINE = 'text-underline',
  TEXT_FONT_STYLE = 'text-font-style',
  BOX_SHADOW = 'box-shadow',
  SELECTED_BORDER = 'selected-border',
  FONT_WEIGHT = 'font-weight',
  TEXT_INPUT_FONT_SIZE = 'text-input-font-size',
  TEXT_INPUT_BG_COLOR = 'text-input-bg-color',
  ICON_SIZE = 'icon-size',
  DIMENSIONS = 'dimensions',
  DISABLED_BORDER_COLOR = 'disabled-border',
  HEIGHT_WIDTH = 'height-width',
  INPUT_BORDER = 'input-border',
  INPUT_IDLE_BACKGROUND_COLOR = 'input-idle-background-color',
  INPUT_IDLE_BORDER_COLOR = 'input-idle-border-color',
  INPUT_ACTIVE_BACKGROUND_COLOR = 'input-active-background-color',
  ICON_COLOR = 'icon-color',
  RIGHT_ICON_SIZE = 'right-icon-size',
  RIGHT_ICON_COLOR = 'right-icon-color',
  LEFT_ICON_SIZE = 'left-icon-size',
  LEFT_ICON_COLOR = 'left-icon-color',
  MIN_WIDTH = 'min-width',
  TITLE_FONT = 'title-font',
  DESCRIPTION_FONT = ' description-font',
  ACTIVE_BACKGROUND_COLOR = 'active-background-color',
  IN_ACTIVE_BACKGROUND_COLOR = 'in-active-background-color',
  ACTIVE_BORDER_COLOR = 'active-border-color',
  IN_ACTIVE_BORDER_COLOR = 'in-active-border-color',
  ALERT_LEFT_ICON = 'alert-left-icon',
  ALERT_RIGHT_ICON = 'alert-right-icon',
  ALERT_BORDER = 'alert-border',
}

export const commonContentStyleList = [
  BaseCssProperties.MARGIN,
  BaseCssProperties.PADDING,
  BaseCssProperties.BORDER,
  BaseCssProperties.COLOR,
  BaseCssProperties.TEXTALIGN,
  BaseCssProperties.BACKGROUND_COLOR,
  BaseCssProperties.V_ALIGN,
  BaseCssProperties.BOX_SHADOW,
];

export const buttonNestedWidgetConfigStyles = [
  BaseCssProperties.MARGIN,
  BaseCssProperties.PADDING,
  BaseCssProperties.BORDER,
  BaseCssProperties.BOX_SHADOW,
  BaseCssProperties.COLOR,
  BaseCssProperties.BACKGROUND_COLOR,
  BaseCssProperties.FONT_SIZE,
  BaseCssProperties.FONT_WEIGHT,
  BaseCssProperties.TEXT_BOLD,
  BaseCssProperties.TEXT_FONT_STYLE,
  BaseCssProperties.TEXT_ITALIC,
  BaseCssProperties.TEXT_UNDERLINE,
  BaseCssProperties.V_ALIGN,
  BaseCssProperties.TEXTALIGN,
];

export const cardNestedWidgetConfigStyles = [
  BaseCssProperties.MARGIN,
  BaseCssProperties.PADDING,
  BaseCssProperties.BORDER,
  BaseCssProperties.BOX_SHADOW,
  BaseCssProperties.COLOR,
  BaseCssProperties.HEIGHT_WIDTH,
  BaseCssProperties.BACKGROUND_COLOR,
  BaseCssProperties.OBJECT_FIT,
];
export const commonTextContentStyleList = [
  BaseCssProperties.COLOR,
  BaseCssProperties.FONT_SIZE,
  BaseCssProperties.TEXT_BOLD,
  BaseCssProperties.TEXT_ITALIC,
  BaseCssProperties.TEXT_UNDERLINE,
  BaseCssProperties.TEXT_FONT_STYLE,
];

export enum WidgetPropertiesType {
  HEADER_CONFIG = 'headerConfig',
  SUBHEADER_CONFIG = 'subheaderConfig',
  TABLE_HEADER_CONFIG = 'tableHeaderConfig',
  TABLE_GRID_SETTING = 'tableGridSetting',
  TABLE_ROW_SETTING = 'tableRowSetting',
}

export enum ValidationRulesType {
  REQUIREDVALIDATION = 'requiredvalidation',
}
export enum integratedFinanceSanboxMode {
  Live = 'live',
  Test = 'test',
}

export enum integratedFinancePricingMode {
  Explicit = 'explicit',
  Implicit = 'implicit',
}

export enum ImageType {
  IMAGE = 'image',
  LOGO = 'logo',
}

export enum PopupSettingType {
  IMAGE_LIBRARY = 'image_library',
  IMAGE_WIDGET = 'image_widget',
  CAROUSEL_WIDGET = 'carousel_widget',
  PAGE_BACKGROUND = 'page_background',
}

export enum PopupButtonType {
  CANCEL = 'cancel',
  CLOSE = 'close',
}

export type ThemeConfig = {
  colour_palette: ColourPaletteType;
  additional_css?: string;
  fontFamily: string;
  logo: string;
  logo_title?: string;
};

export type ProfilePageAttributes = {
  css_config: BaseCssConfig;
  additional_configs: {
    additional_fields: ExtraAttributeField[];
    validators: [];
  };
  'terms-and-condition': boolean;
  'privacy-policy': boolean;
};

export type VerificationPage = {
  organisations: {
    individual: {
      css_config: BaseCssConfig;
      additional_configs: {
        additional_fields: ExtraAttributeField[];
        validators: [];
      };
      kycVerficationEmail: boolean;
      kycVerficationPhone: boolean;
    };
  };
};

export type PaymentPage = {
  organisations: {
    individual: {
      addingABeneficiary: boolean;
      additional_configs: {
        additional_fields: [];
        validators: [];
      };
      removingABeneficiary: boolean;
    };
  };
};

export type QuestionWidgetPage = {
  organisations: {
    individual: {
      additional_configs: {
        additional_fields: ExtraAttributeField[];
        validators: [];
      };
      layout: LayoutWidgetConfig[];
    };
  };
};

export type ImageWidgetPage = {
  organisations: {
    individual: {
      additional_configs: {
        additional_fields: ExtraAttributeField[];
        validators: [];
      };
      layout: LayoutWidgetConfig[];
    };
  };
};

export type TextItemWidgetPage = {
  organisations: {
    individual: {
      additional_configs: {
        additional_fields: ExtraAttributeField[];
        validators: [];
      };
      layout: LayoutWidgetConfig[];
    };
  };
};

export type UserPropertiesTable = {
  organisations: {
    individual: {
      css_config: CssConfig;
      columns: ColumnConfig[];
    };
  };
};

export type ColumnConfig = {
  [key: string]: {
    label: string;
    value: string;
    selected: boolean;
    position: number;
  };
};

export type CssConfig = {
  paddingTop: number;
  paddingLeft: number;
  paddingRight: number;
  paddingBottom: number;
  paddingUnit: string;
  marginTop: number;
  marginLeft: number;
  marginRight: number;
  marginBottom: number;
  marginUnit: string;
  textColor: string;
};

export type SystemRole = {
  id: string;
  title: string;
  slug: string;
  prevValue?: string;
  status?: RoleStatus;
  userType?: string;
  alternativeThemes?: { value: string; isPrimary: boolean }[];
};

export function getPrimaryTheme(themes?: { value: string; isPrimary: boolean }[]): string | undefined {
  const primary = themes?.find((theme) => theme.isPrimary === true);

  return primary?.value ?? undefined;
}

export type SystemRoles = {
  [key: string]: SystemRole;
};

export type SystemSubscriptionPlan = {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type SystemSystemConfig = {
  systemName: string;
  legalEntityName: string;
  theme: ThemeConfig;
  alternativeThemes?: {
    [key: string]: AlternativeSystemSystemConfig;
  };
  loginPageConfig: {
    logoEnabled: boolean;
  };
};

export type AlternativeSystemSystemConfig = Omit<SystemSystemConfig, 'alternativeThemes'>;

export type IconConfigType = {
  name: string;
  size: string;
  color: string;
  unit: string;
};
export interface AlertStyleType extends Partial<BaseCssConfig> {
  minWidth: number;
  minWidthUnit: string;
  backgroundOpacity: number;
  borderWidthTop?: number;
  borderWidthBottom?: number;
  borderWidthLeft?: number;
  borderWidthRight?: number;
  borderWidthUnit?: string;
  textStyle: {
    titleFontSize: number;
    titleFontFamily: string;
    descriptionFontSize: number;
    descriptionFontFamily: string;
  };
  iconConfiguration: { [iconType: string]: IconConfigType };
}

export type AlertBehaviourSettings = {
  dismissalType: DismissalTypeDesktop | DismissalTypeMobile;
  dismissAfter: string;
  position: AlertPositions;
};

export type AlertItemConfigurationType = {
  behaviourSettings: AlertBehaviourSettings;
  styles: AlertStyleType;
  htmlString?: string;
};

export type AlertStyleConfigurationType = {
  [device in ActiveDevice]?: {
    [type in AlertTypes]: AlertItemConfigurationType;
  };
};

export type UiSystemConfig = {
  system: SystemSystemConfig;
  zones: SystemZones;
  navbar: { [key: string]: NavBar };
  customData: customDataType;
  alertsConfig?: AlertStyleConfigurationType;
};
export type GetSystemConfig = {
  config_key: string;
  config_value: SystemConfiguration;
};

export interface ProviderConfigInterface {
  currency: string;
  checkPayeeEnabled: boolean;
  smsAuthenticationEnabled: boolean;
}

export type AccountProviderService = {
  provider: 'integratedFinance' | 'modulr';
  cardsConfig?: CardConfigValuesType;
  paymentRequest?: accountServicePaymentRequest;
  modulrConfig?: {
    currency: string;
    applicant: boolean;
    tcsVersion: number;
    sandboxConfig: {
      sortCode: string;
      customerId: string;
      fundAmount: number;
      sourceAccountId: string;
      addFundEnabled: boolean;
    };
    supportedCurrency: string[];
    sandboxEnabled: boolean;
    virtualCardAmount: number;
    virtualCardExpiry: number;
    accountProductCode: string;
    expectedMonthlySpend: number;
    virtualCardProductCode: string;
    physicalCardProductCode: string;
    physicalCardAmount: number;
    physicalCardExpiry: number;
    checkPayeeEnabled: boolean;
    smsAuthenticationEnabled: boolean;
    smsAuthenticationExclusion: {
      [key: string]: string[];
    };
  };
  integratedFinanceConfig?: IntegratedFinaceConfigValuesType;
  enableBeneficiaryFirstAndLastName?: boolean;
};

export type FeeManagementServiceType = {
  enabled: boolean;
  retryFailedFeeEnabled: boolean;
  feeReversalEnabled: boolean;
};

export type CardConfigValuesType = {
  enabled: boolean;
  provider: 'integratedFinance' | 'accomplish' | 'payabl';
  // if cardsEnabled false then assume this is empty otherwise present
  accomplishConfig?: {
    binIdetc: ''; // etc etc
  };
  integratedFinanceConfig?: {
    serviceProvider: 'thredd';
  };
  // json object with a unique key for each card type available
  cardOptions: {
    [cardProductIdentifier: string]: CardConfig;
  };
};

export type CardConfig = {
  // alias is name of card may or may not be applicable depending on provider
  defaultAlias?: string;
  // virtual of physical card
  type: 'virtual' | 'physical';
  // might not be needed for accomplish
  accomplishAttributes?: {
    whatever: ''; // etc etc
    // might want to put atm limits, fdaily, monthly etc defaults here
  };
  // threddAttributes only applicable on integratedFinance using thredd serviceProvider
  threddAttributes?: {
    threddCardProduct: string;
    threddDesignId: string;
    threddImageId?: string;
    threddCardManufacturer?: string;
    threddDeliveryMethod?: string;
    threddCarrierType?: string;
  };
  // more optional attribtes depending on the card integration...
};

export type NotificationProviderService = {
  provider: string;
  profile_notification_duplicate_delay: number;
};

export type IntegratedFinaceConfigValuesType = {
  currency: string;
  currencyalias: string;
  accountClosureOnZeroBalance?: boolean;
  sandboxConfig: {
    clientId: string;
    addFundEnabled: boolean;
    fundAmount: number;
    sandboxMode: integratedFinanceSanboxMode;
  };
  outboundPaymentAtttributePlugins?: string[];
  supportedCurrency: string[];
  enableHeaders: boolean;
  sandboxEnabled: boolean;
  virtualCardAmount: number;
  virtualCardExpiry: number;
  expectedMonthlySpend: number;
  physicalCardAmount: number;
  physicalCardExpiry: number;
  serviceProviderId: string;
  serviceProvider?: string;
  transferReasonId: string;
  checkPayeeEnabled: boolean;
  smsAuthenticationEnabled: boolean;
  beneficiaryCheckPayeeProvider?: string;
  smsAuthenticationExclusion: {
    [key: string]: string[];
  };
  debitOnSettlementExchangeEnabled?: boolean;
  // used to know if we should set balance as the IF available balance minus the pending trasnactions + excahnges
  calculatePayableBalance?: boolean;
  pricing: {
    implicit: {
      sourcingMethod: integratedFinancePricingMode;
    };
    explicit: {
      sourcingMethod: integratedFinancePricingMode;
      enabled: true;
      chargingType: string;
      chargingMethod: string;
    };
    method: integratedFinancePricingMode;
    attributes: {
      [key: string]: string | boolean | number;
    };
  };
  exchangePricing: {
    implicit: {
      sourcingMethod: integratedFinancePricingMode;
    };
    explicit: {
      sourcingMethod: integratedFinancePricingMode;
      enabled: true;
      chargingType: string;
      chargingMethod: string;
    };
    method: integratedFinancePricingMode;
    attributes: {
      [key: string]: string | boolean | number | object;
    };
  };
  feesConfig?: {
    applyFeeKey: string;
    revertFeeKey: string;
  } | null;
  alternativeBackends?: {
    [key: string]: IntegratedFinaceConfigValuesType;
  };
};

export type CompanyHouseService = {
  provider: string;
  companyHouseConfig: {
    environment: string;
    sandboxConfig: {
      api_key: string;
      api_url: string;
    };
    liveConfig: {
      api_key: string;
      api_url: string;
    };
  };
};
export type VehicleVerificationService = {
  provider: string;
  dvlaUkConfig: {
    apiKey: string;
    url: string;
  };
};

export type ImportUserService = {
  provider: string;
  azureConfig: {
    clientId: string;
    tenantId: string;
    accessKey: string;
  };
};

export type KycProviderService = {
  provider: string;
  sumsubConfig: {
    apiKey: string;
    url: string;
  };
};

export type SystemConfiguration = {
  roles: SystemRoles;
  subscription: SystemSubscriptionPlan;
  ui: UiSystemConfig;
  account_service?: { provider: PaymentProvider };
  packs?: PACKS[];
};

export type ProjectConfiguration = {
  system: {
    enabled_services: {
      accounts_and_payments: boolean;
    };
  };
  onboardingFlow?: OnboardingConfigFlowTypes;
  account_service: AccountProviderService;
  'system-entities': {
    organisations: {
      group: {
        extended_attributes: [];
      };
      individual: {
        extended_attributes: [];
      };
    };
  };
  notification_service: NotificationProviderService | null;
  locality: string;
  company_house_service: CompanyHouseService | null;
  vehicle_verification_service: VehicleVerificationService | null;
  import_users_service: ImportUserService | null;
  kyc_provider_service: KycProviderService | null;
  kyc_regulated: boolean;
  sms_config?: {
    twilio_from_sender: string;
    verify_message_handlebar_template?: string;
    verify_ttl_minutes?: number;
  };
  // Probaly should be moved to nested object
  keycloak_auth_url: string;
  keycloak_realm: string;
  keycloak_client_id: string;
  organisationDefaultRole: string;

  publish_url: string;
  file_storage_url: string;
  site_url: string;
  defaultCurrency: string;
  checkPayeeEnabled: boolean;
  smsAuthenticationEnabled: boolean;
  orgCheck: OrgCheckTypes;
  default_locale: Locale;
  fee_management: {
    enabled: boolean;
    retryFailedFeeEnabled: boolean;
    feeReversalEnabled: boolean;
  };
};

export type OnboardingZone = {
  roles: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          organisations: {
            group: Record<string, never>;
            individual: {
              layout: LayoutWidgetConfig[];
            };
          };
        };
      };
    };
  };
};

export type ChartsZone = {
  roles: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          organisations: {
            group: Record<string, never>;
            individual: {
              layout: LayoutWidgetConfig[];
            };
          };
        };
      };
    };
  };
};

export type LandingZone = {
  roles: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          organisations: {
            group: Record<string, never>;
            individual: {
              layout: LayoutWidgetConfig[];
            };
          };
        };
      };
    };
  };
};

export type VerificationZone = {
  roles: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          organisations: {
            group: Record<string, never>;
            individual: {
              layout: LayoutWidgetConfig[];
              sumsublayout: LayoutWidgetConfig[];
              enabled: boolean;
              additional_configs: {
                validators: ExtraAttributeField[];
                additional_fields: ExtraAttributeField[];
              };
              kycVerficationEmail: boolean;
              kycVerficationPhone: boolean;
            };
          };
        };
      };
    };
  };
};

export type ProfileZone = {
  roles: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          organisations: {
            group: Record<string, never>;
            individual: {
              additional_configs: {
                additional_fields: ExtraAttributeField[];
                validators: [];
              };
              'terms-and-condition': boolean;
              'privacy-policy': boolean;
              css_config: BaseCssConfig;
            };
          };
        };
      };
    };
  };
};

export type onboardingWidgets = {
  name: WidgetLayout;
  birthday: WidgetLayout;
  address: WidgetLayout;
  phone: WidgetLayout;
  email: WidgetLayout;
  company_details: WidgetLayout;
};

export type LandingPageConfig = {
  // defines extra info needed to be collected or performed in initial user sign up
  organisations: {
    individual: {
      showCardBalance: boolean;
      showMonthlyIncome: boolean;
      showCardBackSideDetailsIcon: boolean;
      showMonthlyExpenditure: boolean;
      additional_configs: {
        additional_fields: ExtraAttributeField[];
        validators: [];
      };
    };
    group: object;
  };
};

export type GeneralPageConfig = {
  organisations: {
    individual: {
      showCardBalance: boolean;
      showMonthlyIncome: boolean;
      showCardBackSideDetailsIcon: boolean;
      showMonthlyExpenditure: boolean;
      additional_configs: {
        additional_fields: ExtraAttributeField[];
        validators: [];
      };
    };
    group: object;
  };
};

export type SignUp = {
  additional_configs: {
    validators: ExtraAttributeField[];
    additional_fields: ExtraAttributeField[];
  };
  verificationViaOTP: boolean;
};

export interface ExtraAttributeField {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  options?: any;
  name: string;
  displayName: string;
  type: ExtraAttributeFieldType;
  validators?: ValidatorType[];
}

export interface ExtraAttributeFieldDropdown extends ExtraAttributeField {
  options: { key: string; value: string }[];
}
export type ExtraAttributeFieldTextField = ExtraAttributeField;

export enum ExtraAttributeFieldType {
  TEXTFIELD,
  DROPDOWN,
  CHECKBOX,
  RADIO,
}

export enum PageConfigSwitchType {
  PageHeader = 'pageHeader',
  LabelsAsPlaceholder = 'labelsAsPlaceholder',
  IsCustomGridActive = 'isCustomGridActive',
  IsBodyScrollingEnabled = 'isBodyScrollingEnabled',
  IsUsingHtmlStructure = 'isUsingHtmlStructure',
  IsAutoRedirectEnabled = 'isAutoRedirectEnabled',
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface, prettier/prettier
export interface ValidatorType {}

export class AccountPageWidgetConfig {
  name: string;
  position: number;
  attributes: unknown;

  constructor(name: string, position: number, attributes: unknown) {
    this.name = name;
    this.position = position;
    this.attributes = attributes;
  }
}

export type LayoutWidgetConfig = {
  name: ComponentLabel;
  position: number;
  widgetIndex?: number;
  type?: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  attributes: any;
  uniqueId: number | string;
  // Need to remove any as a type here and move all wdgets over to grids
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  gridPosition: GridPosition | any;
  layout?: LayoutWidgetConfig[];
};

export type ColourPaletteType = {
  name: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  [key: string]: any;
  palette: {
    [key: string]: string;
    '0': string;
    '1': string;
    '2': string;
    '3': string;
    '4': string;
    '5': string;
    '6': string;
  };
  border_colors: {
    [key: string]: string;
    '0': string;
    '1': string;
    '2': string;
  };
  typography: {
    /**
     * @deprecated
     */
    fontFamily?: string;
    palette: {
      [key: string]: string;
      '0': string;
      '1': string;
      '2': string;
    };
  };
  alerts: {
    [key: string]: string;
    '0': string;
    '1': string;
    '2': string;
    '3': string;
  };
};

export interface CustomColourType {
  name: string;
  code: string;
}

export interface PaletteTypeColourOptions {
  primary: string;
  background: string;
  surface: string;
  borderColor: string;
  onPrimary: string;
  onPrimaryMuted: string;
  onBackground: string;
  onBackgroundMuted: string;
  onSurface: string;
  onSurfaceMuted: string;
  active: string;
  hover: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

export interface NewColourPaletteType {
  main: Pick<PaletteTypeColourOptions, 'primary' | 'background' | 'surface' | 'borderColor'>;
  typography: Pick<
    PaletteTypeColourOptions,
    'onPrimary' | 'onPrimaryMuted' | 'onBackground' | 'onBackgroundMuted' | 'onSurface' | 'onSurfaceMuted'
  >;
  states: Pick<PaletteTypeColourOptions, 'active' | 'hover'>;
  status: Pick<PaletteTypeColourOptions, 'info' | 'success' | 'warning' | 'error'>;
  customColors: CustomColourType[];
}

export type ButtonSizeConfigurations = {
  'padding-top': string;
  'padding-right': string;
  'padding-bottom': string;
  'padding-left': string;
  'margin-top': string;
  'margin-right': string;
  'margin-bottom': string;
  'margin-left': string;
  paddingWithIcon?: {
    gap: string;
    'padding-right': string;
    'padding-left': string;
  };
};

export enum InputLabelOptions {
  STATIC = 'static',
  PLACEHOLDER = 'placeholder',
  FLOATING = 'floating',
}

export enum ButtonSizeOptions {
  SMALL = 'small',
  LARGE = 'large',
  FULL_WIDTH = 'full-width',
}

export type ButtonAndInputStatesConfig = {
  textColor: {
    color: string;
  };
  labelColor: {
    color: string;
  };
  'border-color': string;
  'background-color': string;
};

export type ButtonConfigBaseStyles = {
  'justify-content': string;
  'align-items': string;
  'border-style': string;
  'box-shadow': string;
  'border-top-left-radius': string;
  'border-top-right-radius': string;
  'border-bottom-right-radius': string;
  'border-bottom-left-radius': string;
  'border-top-width': string;
  'border-left-width': string;
  'border-bottom-width': string;
  'border-right-width': string;
};

export type BorderRadiusStyles = Pick<
  ButtonConfigBaseStyles,
  | 'border-top-left-radius'
  | 'border-top-right-radius'
  | 'border-bottom-right-radius'
  | 'border-bottom-left-radius'
>;
export type ButtonIconConfigurations = {
  type: 'left' | 'right';
  iconName: string;
  height: string;
  width: string;
  color: string;
};

export type ButtonConfigurations = {
  idle: Omit<ButtonAndInputStatesConfig, 'labelColor'>;
  active: Omit<ButtonAndInputStatesConfig, 'labelColor'>;
  hover: Omit<ButtonAndInputStatesConfig, 'labelColor'>;
  baseStyles: ButtonConfigBaseStyles;
  size: {
    [ButtonSizeOptions.SMALL]: ButtonSizeConfigurations;
    [ButtonSizeOptions.LARGE]: ButtonSizeConfigurations;
    [ButtonSizeOptions.FULL_WIDTH]: ButtonSizeConfigurations;
  };
  borderRadiusClass: BorderRadiusClasses;
  iconConfigurations?: ButtonIconConfigurations;
};

export type TypographyStyleConfigurations = {
  'font-weight': string;
  'text-decoration': string;
  'font-size': string;
  'letter-spacing': string;
  'line-height': string;
  'margin-bottom': string;
};

export interface TypographyStyleConfigurationsForm {
  'font-weight': FormControl<string>;
  'text-decoration': FormControl<string>;
  'font-size': FormControl<string>;
  'letter-spacing': FormControl<string>;
  'line-height': FormControl<string>;
  'margin-bottom': FormControl<string>;
}

export enum ButtonTypes {
  PRIMARY = 'primaryButton',
  SECONDARY = 'secondaryButton',
  TERTIARY = 'tertiaryButton',
  MENU = 'menuButton',
}

export type InputBaseStyles = {
  'align-items': string;
  'justify-content': string;
  'padding-top': string;
  'padding-right': string;
  'padding-bottom': string;
  'padding-left': string;
  height: string;
  width: string;
  'border-top-left-radius': string;
  'border-top-right-radius': string;
  'border-bottom-right-radius': string;
  'border-bottom-left-radius': string;
  'border-top-width': string;
  'border-left-width': string;
  'border-bottom-width': string;
  'border-right-width': string;
  'box-shadow': string;
  'border-style': string;
};

export type InputLabelConfig = {
  type: InputLabelOptions;
  'align-items': string;
  'justify-content': string;
  'padding-top': string;
  'padding-right': string;
  'padding-bottom': string;
  'padding-left': string;
};

export type OtpInputFieldSpacingConfig = {
  'margin-right': string;
};

export interface InputFieldsConfigurations {
  idle: ButtonAndInputStatesConfig;
  active: ButtonAndInputStatesConfig;
  labelConfig: InputLabelConfig;
  inputStyles: InputBaseStyles;
  textStyleClass: string;
  borderRadiusClass: BorderRadiusClasses;
  otpInputFieldSpacing: OtpInputFieldSpacingConfig;
}

export interface GlobalThemeConfiguration {
  themeName: string;
  generalConfigurations: {
    companyInfo: {
      name: string;
      legalEntityName: string;
      logo: string;
      logoTitle: string;
      logoFileName: string;
    };
    defaultSystemFont: boolean;
    fontFamily: string;
    palette: NewColourPaletteType;
  };
  buttons: {
    [ButtonTypes.PRIMARY]: ButtonConfigurations;
    [ButtonTypes.SECONDARY]: Pick<ButtonConfigurations, 'idle' | 'active' | 'hover'>;
    [ButtonTypes.TERTIARY]: Pick<ButtonConfigurations, 'idle' | 'active' | 'hover'>;
    [ButtonTypes.MENU]: ButtonConfigurations;
  };
  typography: {
    [key in TypographyKeys]: TypographyStyleConfigurations;
  };
  inputFields: InputFieldsConfigurations;
  radius: {
    [key in BorderRadiusClasses]: BorderRadiusStyles;
  };
}

export type CarouselWidgetPage = {
  organisations: {
    individual: {
      additional_configs: {
        additional_fields: ExtraAttributeField[];
        validators: [];
      };
      layout: LayoutWidgetConfig[];
    };
  };
};

export type UerManagementZone = {
  roles: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          organisations: {
            group: Record<string, never>;
            individual: {
              layout: LayoutWidgetConfig[];
            };
          };
        };
      };
    };
  };
};

export type MenuZone = {
  roles: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          organisations: {
            group: Record<string, never>;
            individual: {
              layout: LayoutWidgetConfig[];
            };
          };
        };
      };
    };
  };
  title?: string;
};

export type CardManagementZone = {
  roles: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          organisations: {
            group: Record<string, never>;
            individual: {
              layout: LayoutWidgetConfig[];
            };
          };
        };
      };
    };
  };
  title?: string;
};

export enum FieldName {
  VIEW_CARD_OPTION = 'viewCardOption',
  VIEW_CARD_SEPARATELY = 'ViewCardSeparately',
  CARD_MANAGEMENT_TITLE = 'Card Management',
  CARD_DETAILS_TITLE = 'Card details',
}

export type DeviceData = {
  deviceType: SupportedDevice;
  deviceWidth: number;
  deviceHeight: number;
  numberCols: number;
  gridCols: number;
  gridRows: number;
  gridster: boolean;
};

export type WidgetSection = {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  attributes: any;
  name: string;
  position: number;
  widgetIndex: number;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  defaultBeneficiaryList?: any[];
};
export type PageWidgetConfig = {
  name: ComponentLabel;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  attributes: any;
  layout?: LayoutWidgetConfig[];
};

export enum ComponentNames {
  MONTHLYINCOME = 'monthlyIncome',
  MONTHLYSPEND = 'monthlySpend',
  SIMPLEBALANCE = 'simpleBalance',
  MONTHLYBALANCE = 'monthlyBalance',
  BALANCECHART = 'balanceChart',
  VIRTUALCARD = 'virtualCard',
  QUICKLINK = 'quickLinks',
  PAYMENTBUTTON = 'paymentButton',
  FOOTERNAV = 'footerNav',
  POPUPSELECTOR = 'popupSelector',
  AMOUNTSUMMARY = 'ammountSummaryComponent',
  CREDITDEBITSUMMARY = 'creditdebitSummaryComponent',
}

export type ProtectedProjectConfig = {
  kyc_regulated: boolean;
  provider: string;
  sandboxMode: integratedFinanceSanboxMode | string;
  checkPayeeEnabled: boolean;
  supportedCurrency: string[];
  enableHeaders: boolean;
  bankProvider: string;
  paymentRequest: accountServicePaymentRequest;
  onboardingFlow: OnboardingConfigFlowTypes;
  defaultCurrency: string;
  smsAuthenticationEnabled: boolean;
  smsAuthenticationExclusion: {
    [key: string]: string[];
  };
  enableBeneficiaryFirstAndLastName: boolean;
  defaultLocale: string;
};

export const defaultZoneConfiguration = {
  zonePageType: 'singlePage',
  zoneType: ZONETYPEOPTION.DEFAULT,
} as ZoneConfiguration;

export type DeviceLocalData = {
  deviceId?: string;
  manufacturer?: string;
  os?: string;
  deviceModel?: string;
};

export type State = {
  name: string;
  state_code: string;
};

export type accountServicePaymentRequest = {
  enabled: boolean;
  paymentApproverRoles: string[];
  paymentRequestorRoles: string[];
  paymentInitiatorRoles: string[];
};
