import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { baseCssConfigStyle } from '@finxone-platform/shared/sys-config-types';

@Component({
  selector: 'finx-button',
  templateUrl: './finx-button.component.html',
  styleUrls: ['./finx-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FinxButtonComponent),
      multi: true,
    },
  ],
})
export class FinxButtonComponent implements ControlValueAccessor {
  isFocused = false;
  @Input() id: string;
  @Input() type = 'primary';
  @Input() width = '100%';
  @Input() class = '';
  @Input() labeltext = '';
  @Input() disabled = false;
  @Input() rounded = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() value: any = '';
  @Input() buttonStyle:
    | {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [klass: string]: any;
      }
    | null
    | undefined;
  @Input() lefticon = '';
  @Input() righticon = '';
  @Input() public rightIconSize?:
    | {
        width: string;
        height: string;
      }
    | baseCssConfigStyle;
  @Input() public leftIconSize?:
    | {
        width: string;
        height: string;
      }
    | baseCssConfigStyle;
  @Input() public rightIconClass = '';
  @Input() public isAllowedGlobalStyling = false;

  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-empty-function
  onModelChange: Function = () => {};
  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-empty-function
  onModelTouched: Function = () => {};
  constructor(public el: ElementRef, private cd: ChangeDetectorRef) {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(value: any): void {
    this.value = value;
    this.cd.markForCheck();
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.markForCheck();
  }
  onClick = () => {
    this.isFocused = false;
  };
}
