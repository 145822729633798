<ng-container *ngIf="localeContent$ | async as locale">
  <div class="pin-container">
    <!-- Header Section -->
    <div class="header-section is-vertical-align is-center">
      <div class="user-info">
        <span class="lock-icon">🔒</span>
        <span class="email">example&#64;email.com</span>
      </div>
      <div class="placeholder-image">
        <mat-icon svgIcon="customer-logo"></mat-icon>
      </div>
      <h2 class="title">{{ locale.loginEnterPinTitle }}</h2>
      <p class="subtitle">{{ locale.loginEnterPinSubTitle }}</p>
    </div>

    <!-- Error Display -->
    <div *ngIf="error" class="error-message">
      {{ error }}
    </div>

    <!-- Desktop PIN Boxes -->
    <ng-container *ngIf="!isMobile">
      <finxone-web-frontend-pin-boxes
        [(pin)]="pin"
        [error]="error"
        (pinBoxes)="checkPinComplete()"
      ></finxone-web-frontend-pin-boxes>
    </ng-container>

    <!-- Mobile PIN Pad -->
    <ng-container *ngIf="isMobile">
      <finxone-web-frontend-pin-pad
        [(pin)]="pin"
        [error]="error"
        (pinPad)="checkPinComplete()"
      ></finxone-web-frontend-pin-pad>
    </ng-container>

    <div class="user-info">
      <span class="alt-flow" (click)="onAltFlowClick($event)">{{ locale.doTryAnotherWay }}</span>
    </div>
  </div>
</ng-container>
