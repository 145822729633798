import { NgModule } from '@angular/core';
import { WorkflowFormGuard } from './workflow-form.guard';
import { WorkflowFormsService } from './workflow-forms.service';

@NgModule({})
export class WorkflowFormsModule {
  // Done this way to ensure only one instance exists in the entire application even inside the lazy loaded modules
  // see the WorkflowFormsModule.forRoot() import in AppModule as well
  static forRoot() {
    return {
      ngModule: WorkflowFormsModule,
      providers: [WorkflowFormsService, WorkflowFormGuard],
    };
  }
}
