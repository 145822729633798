<button
  [id]="id"
  [class]="class"
  [type]="type"
  [ngClass]="
    isAllowedGlobalStyling &&
    ((lefticon !== 'none' && lefticon !== '') || (righticon !== 'none' && righticon !== ''))
      ? 'with-icon'
      : ''
  "
  [disabled]="disabled"
  [class.is-disabled]="disabled"
  (click)="onClick()"
  [ngStyle]="buttonStyle"
>
  <mat-icon *ngIf="lefticon" [ngStyle]="leftIconSize" [svgIcon]="lefticon" class="button-icon-svg"></mat-icon>
  <ng-container *ngIf="class.includes('btn-small'); else btnNormal">
    <small [innerHTML]="labeltext | safeHtml"></small>
  </ng-container>
  <ng-template #btnNormal>
    <span [innerHTML]="labeltext | safeHtml"></span>
  </ng-template>
  <mat-icon
    *ngIf="righticon"
    [ngStyle]="rightIconSize"
    [svgIcon]="righticon"
    class="button-icon-svg"
    [ngClass]="!isAllowedGlobalStyling ? 'ml-auto' : ''"
    [class]="rightIconClass"
  ></mat-icon>
</button>
