import { Router } from '@angular/router';
import { CardManagementService } from '@app/finxone-web-frontend/app/lib/services/card-management-service/card-management-service';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { APP_ZONES } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { take, tap } from 'rxjs';
import { CancelCardAction } from '../../../actions/card-management.action';
import { CardState } from '../../../state/card.state';
import { formatNameAsUrl } from '../../zone-url.utils';

export function shareCvvDetails(cardManagementService: CardManagementService) {
  const flutterWebView = (window as any).flutter_inappwebview;
  const textContent = `cvv2:${cardManagementService.cvvDetails.cvv2} pan:${cardManagementService.cvvDetails.pan} pin:${cardManagementService.cvvDetails.pin}`;
  if (flutterWebView) {
    flutterWebView.callHandler('shareSheetHandler', [textContent]);
  }
}

export function onCancelCard(store: Store, alertHandlerService: AlertHandlerService, router: Router): void {
  const cardId = store.selectSnapshot(CardState.getCurrentCardId);
  if (!cardId) {
    alertHandlerService.showAlertFn('error', 'Card information is missing.');
    return;
  }
  store
    .dispatch(new CancelCardAction(cardId))
    .pipe(
      take(1),
      tap({
        next: () => {
          router.navigateByUrl(`/zones/${formatNameAsUrl(APP_ZONES.CARD_MANAGEMENT_ZONE)}/card-cancelled`);
        },
        error: (error) => {},
      }),
    )
    .subscribe();
}
