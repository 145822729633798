export class Config {
  'api_endpoints'!: any;
  auth!: AuthConfig;
  hotjarSiteId?: number;
  novuApplicationIdentifier?: string;
  idleTimeout?: number;
  multiplayerAppEditorEnabled?: boolean;
  features?: {
    bfCacheAlertDetection?: boolean;
    // used in app builder angular project
    mandatorySaveAppEditor?: boolean;
  };
  featureFlagProvider?: {
    provider: string;
    flagsmithEnvironmentId?: string;
    unleashAppUrl?: string;
    unleashAppName?: string;
    unleashClientKey?: string;
  };
}

export class AuthConfig {
  realm!: string;
  client_id!: string;
}
