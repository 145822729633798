<div class="pin-display">
  <div class="pin-dots">
    <div
      class="dot"
      *ngFor="let _ of [].constructor(maxLength); let i = index"
      [class.filled]="pin.length > i"
    ></div>
  </div>
</div>

<div class="pin-pad is-vertical-align">
  <div class="pin-row" *ngFor="let row of pinPadMobileLayout">
    <button
      class="is-center"
      *ngFor="let num of row"
      [class.numeric]="isNumber(num)"
      [class.action]="isAction(num)"
      [class.biometric]="isBiometric(num)"
      [class.hidden]="num === 'empty'"
      (click)="handleMobilePinPad(num)"
    >
      <ng-container [ngSwitch]="num">
        <span *ngSwitchCase="'clear'">⌫</span>
        <span *ngSwitchCase="'biometric'">
          <svg xmlns="http://www.w3.org/2000/svg" class="biometric-icon" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M11.997 5q2.93 0 4.967 2.034T19 11.997t-2.033 4.967T12.003 19t-4.966-2.033T5 12.003t2.034-4.966T11.997 5M12 18q2.5 0 4.25-1.75T18 12t-1.75-4.25T12 6T7.75 7.75T6 12t1.75 4.25T12 18m0-2.884q1.066 0 1.939-.573q.873-.572 1.292-1.543H8.769q.42.971 1.292 1.543q.873.573 1.939.573m-3.23-5.113q0 .345.232.575q.233.23.578.23t.575-.233t.23-.578t-.234-.575t-.578-.23T9 9.426t-.23.577m4.847 0q0 .345.233.575t.578.23t.574-.233t.23-.578t-.233-.575t-.578-.23t-.575.234t-.23.577M2 6V3.616q0-.667.475-1.142T3.614 2H6v1H3.616q-.27 0-.443.173T3 3.616V6zm4 16H3.616q-.667 0-1.142-.475T2 20.386V18h1v2.385q0 .269.173.442t.443.173H6zm12-.192v-1h2.385q.269 0 .442-.174q.173-.173.173-.442v-2.384h1v2.384q0 .667-.475 1.141t-1.14.475zM21 6V3.616q0-.27-.173-.443T20.385 3H18V2h2.385q.666 0 1.14.475T22 3.614V6zm-9 6"
            ></path>
          </svg>
        </span>
        <span *ngSwitchDefault>{{ num }}</span>
      </ng-container>
    </button>
  </div>
</div>