import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  APP_ZONES,
  BaseWidgetProperties,
  RevenirPassportKYCStatus,
  RevenirTripType,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { debounceTime, filter, switchMap, take } from 'rxjs';
import { ResetFormDataAction } from '../../../actions/form-submission.action';
import {
  ConfirmAndArchiveTrip,
  CreateRevenirTrip,
  GetRevenirSubjectStatus,
  MakeTripAsArchiveTrip,
} from '../../../actions/revenir.action';
import { RevenirService } from '../../../services/revenir-service/revenir.service';
import { ApiActions, ApiLoadingState } from '../../../state/api-loading.state';
import { FormActionState } from '../../../state/form-submision.state';
import { RevenirState } from '../../../state/revenir.state';
import { redirectToPage } from '../cta-button-actions.utils';

export function addRevenirTrip(widgetProperties: BaseWidgetProperties, store: Store, router: Router) {
  const data = store.select(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE),
  );
  data
    .pipe(
      switchMap((res) => {
        return store.dispatch(new CreateRevenirTrip(res));
      }),
    )
    .subscribe(() => {
      widgetProperties['urlToNavigate'] = 'trips-home-page';
      widgetProperties['zoneToNavigate'] = APP_ZONES.REVENIR_ZONE;
      store.dispatch(new ResetFormDataAction());
      redirectToPage(router, widgetProperties);
    });
}

export function archiveRevenirTrip(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  router: Router,
  alertHandlerService: AlertHandlerService,
  revenirService: RevenirService,
) {
  const data = store.selectSnapshot(RevenirState.getArchivedRevenirTripDetails);
  store.dispatch(new MakeTripAsArchiveTrip(data as RevenirTripType));
}

export function checkBarcodeScanDoneRevenirTrip(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  router: Router,
  alertHandlerService: AlertHandlerService,
) {
  store.dispatch(new ResetFormDataAction());
  store.dispatch(new GetRevenirSubjectStatus());
  widgetProperties['zoneToNavigate'] = APP_ZONES.REVENIR_ZONE;

  const tripDetails = store.selectSnapshot(RevenirState.getSelectedRevenirTripDetails);
  const isBoardingPassUploaded = tripDetails?.is_boarding_pass_uploaded;
  const countryExit = tripDetails?.country_exit;
  const countryEntry = tripDetails?.country_entry;

  store
    .select(ApiLoadingState.getApiState(ApiActions.revenirKycStatus))
    .pipe(
      filter((apiState) => !apiState.isLoading),
      debounceTime(300),
    )
    .subscribe(() => {
      store
        .select(RevenirState.getRevenirStateDetails)
        .pipe(take(1))
        .subscribe((state) => {
          const status = state.kyc_status;
          if (!status) {
            return;
          }
          // If the Passport is uploaded then check this below condition '.

          /* If the boarding pass has not been uploaded and either the exit or entry country is Belgium, it sets urlToNavigate to 'missing-boarding-pass'. */
          /* If the boarding pass has been uploaded or neither the exit nor entry country is not Belgium, it sets urlToNavigate to 'barcode-scanning' .*/

          // If the status is anything other than "passed" (indicating that the passport is not uploaded), then redirect to 'missing-passport'.

          const statusLowerCase = status?.toLowerCase();

          switch (statusLowerCase) {
            case RevenirPassportKYCStatus.PASSED:
              widgetProperties['urlToNavigate'] =
                !isBoardingPassUploaded && (countryExit === 'BEL' || countryEntry === 'BEL')
                  ? 'missing-boarding-pass'
                  : 'barcode-scanning';
              break;

            case RevenirPassportKYCStatus.PROCESSING:
              alertHandlerService.showAlertFn('error', 'Passport is being processed...');
              break;

            default:
              widgetProperties['urlToNavigate'] = 'missing-passport';
              break;
          }

          redirectToPage(router, widgetProperties);
        });
    });
}
export function confirmScanArchiveRevenirTrip(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  router: Router,
) {
  widgetProperties['urlToNavigate'] = 'confirm-archive-success';
  widgetProperties['zoneToNavigate'] = APP_ZONES.REVENIR_ZONE;
  redirectToPage(router, widgetProperties);
  const tripDetails = store.selectSnapshot(RevenirState.getSelectedRevenirTripDetails);
  store.dispatch(new ConfirmAndArchiveTrip(tripDetails?.trip_id as string));
}

export function downloadTaxFreeForms() {
  const flutterWebView = (window as any).flutter_inappwebview;
  const url = 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf';
  if (flutterWebView) {
    flutterWebView.callHandler('pdfDownloadHandler', [url]);
  } else {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.click();
  }
}

export function skipBoardingPass(widgetProperties: BaseWidgetProperties, router: Router, store: Store) {
  const data = store.selectSnapshot(RevenirState.getSelectedRevenirTripDetails);
  widgetProperties['zoneToNavigate'] = APP_ZONES.REVENIR_ZONE;
  widgetProperties['urlToNavigate'] =
    data && !data.is_boarding_pass_uploaded ? 'trips-home-page' : 'exit-point';

  redirectToPage(router, widgetProperties);
}

export function reviewBoardingPass(widgetProperties: BaseWidgetProperties, router: Router, store: Store) {
  const data = store.selectSnapshot(RevenirState.getSelectedRevenirTripDetails);
  widgetProperties['zoneToNavigate'] = APP_ZONES.REVENIR_ZONE;
  widgetProperties['urlToNavigate'] =
    data && !data.is_boarding_pass_uploaded ? 'barcode-scanning' : 'exit-point';
  redirectToPage(router, widgetProperties);
}

export function passportDetails(widgetProperties: BaseWidgetProperties, store: Store, router: Router) {
  const data = store.selectSnapshot(RevenirState.getSelectedRevenirTripDetails);
  widgetProperties['zoneToNavigate'] = APP_ZONES.REVENIR_ZONE;
  if (
    data &&
    !data.is_boarding_pass_uploaded &&
    (data.country_exit === 'BEL' || data.country_entry === 'BEL')
  ) {
    widgetProperties['urlToNavigate'] = 'missing-boarding-pass';
  } else if (
    (data && data?.is_boarding_pass_uploaded) ||
    (data?.country_exit !== 'BEL' && data?.country_entry !== 'BEL')
  ) {
    widgetProperties['urlToNavigate'] = 'barcode-scanning';
  }
  redirectToPage(router, widgetProperties);
}
