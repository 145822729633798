import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetLocaleVariableContent } from '../actions/locale-variable.action';

export interface LocaleVariableStateModel {
  content: {
    [key: string]: string;
  } | null;
}

@State<LocaleVariableStateModel>({
  name: 'localeVariable',
  defaults: {
    content: null,
  },
})
@Injectable()
export class LocaleVariableState {
  @Selector()
  static getLocaleContent(state: LocaleVariableStateModel) {
    return state.content;
  }

  @Action(SetLocaleVariableContent)
  setLocaleVariableContent(ctx: StateContext<LocaleVariableStateModel>, action: SetLocaleVariableContent) {
    ctx.patchState({
      content: action.content,
    });
  }
}
