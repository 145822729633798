import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AutoDestroy } from '@finxone-platform/shared/base-types';
import { KeycloakCustomService } from '../../../../lib/services/keycloak-wrapper-service/keycloak-custom.service';
import { ConfirmationRequest } from '../../../../lib/services/keycloak-wrapper-service/keycloak-custom.type';
import { LocaleVariableState } from '../../../..//lib/state/locale-variable.state';

type ConfirmationQueryParams = {
  claimType: string;
  confirmationType: string;
  returnUrl: string;
};

@Component({
  selector: 'finxone-web-frontend-app-pin-confirmation',
  templateUrl: './app-pin-confirmation.component.html',
  styleUrl: './app-pin-confirmation.component.scss',
})
export class AppPinConfirmationComponent implements OnInit {
  @AutoDestroy private destroy$: Subject<void> = new Subject<void>();
  public localeContent$: Observable<any>;
  public localeContent: any;

  private claim: string | undefined = '';
  private confirmation: string | undefined = '';
  private returnUrl: string | undefined = '';

  public pin = '';
  public error = '';
  public isMobile = false;
  public readonly MAX_LENGTH: number = 6;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private keycloakService: KeycloakCustomService,
  ) {
    this.localeContent$ = this.store.select(LocaleVariableState.getLocaleContent);
  }

  ngOnInit(): void {
    document.getElementById('loader')?.remove();
    this.isMobile = window.innerWidth <= 768;

    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params: Partial<ConfirmationQueryParams>) => {
        this.claim = params['claimType'];
        this.confirmation = params['confirmationType'];
        this.returnUrl = params['returnUrl'] || '/';

        if (!this.claim || !this.confirmation) {
          console.error('Missing required confirmation parameters');
          this.router.navigate(['/']).then();
        }
      });

    this.localeContent$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (content) => {
        this.localeContent = content; // Might need this to pull out error messages
      },
      error: (error) => {
        console.error('Error loading content:', error);
      },
    });
  }

  public checkPinComplete(): void {
    if (this.pin.length === this.MAX_LENGTH) {
      setTimeout(() => this.onSubmit(), 200);
    }
  }

  private async onSubmit(): Promise<void> {
    try {
      const payload: ConfirmationRequest = this.validateAndCreatePayload();
      const { access_token } = await this.keycloakService.sendConfirmation(payload);

      await this.router.navigate([this.returnUrl], {
        queryParams: { confirmation: access_token },
      });
    } catch (error) {
      console.error(error);
      this.error = 'Incorrect PIN. Please try again.'; // Placeholder - var could be populated via locale content
      this.pin = '';
    }
  }

  private validateAndCreatePayload(): ConfirmationRequest {
    if (!this.claim?.length || !this.confirmation?.length) {
      throw new Error('Missing required fields for confirmation payload');
    }
    if (this.pin.length !== this.MAX_LENGTH || !/^\d+$/.test(this.pin)) {
      throw new Error('Invalid PIN format');
    }
    return {
      claim: this.claim,
      factors: [
        {
          type: this.confirmation,
          value: this.pin,
        },
      ],
    };
  }

  onAltFlowClick(event: Event): void {
    event.preventDefault();
  }
}
