import {
  CardStatusType,
  ReplaceCardRequest,
  SecureCardInfoResponse,
} from '@finxone-platform/shared/sys-config-types';

export class FreezeCard {
  static readonly type = '[CardManagement] FreezeCard';
  constructor(public payload: { accountId: string; cardId: string }) {}
}

export class UnFreezeCard {
  static readonly type = '[CardManagement] UnFreezeCard';
  constructor(public payload: { accountId: string; cardId: string }) {}
}

export class UpdateCardManagement {
  static readonly type = '[CardManagement] UpdateCardManagement';
  constructor(public payload: { cardStatus: CardStatusType }) {}
}

export class ReplaceCard {
  static readonly type = '[CardManagement] ReplaceCard';
  constructor(public payload: ReplaceCardRequest & { accountId: string; cardId: string }) {}
}

export class GetCardSecureToken {
  static readonly type = '[CardManagement] GetCardSecureToken';
  constructor(
    public payload: {
      publicKey: string;
      tokenPurpose: string;
      accountId: string;
      cardId: string;
    },
  ) {}
}

export class GetCardSecureTokenSuccess {
  static readonly type = '[CardManagement] GetCardSecureTokenSuccess';
  constructor(
    public payload: {
      encryptedToken: string;
      encryptedSymmetricKey: string;
      encodedIv: string;
    },
  ) {}
}

export class ChangePin {
  static readonly type = '[CardManagement] ChangePin';
  constructor(
    public payload: {
      oldPin: string;
      newPin: string;
      secureToken: string;
      accountId: string;
      cardId: string;
    },
  ) {}
}

export class CardActivateAction {
  static readonly type = '[CardManagement] CardActivateAction';
  constructor(
    public payload: {
      accountId: string;
      cardId: string;
    },
  ) {}
}

export class GetCVVDetails {
  static readonly type = '[CardManagement] GetCVVDetails';

  constructor(
    public payload: {
      secureToken?: string;
      accountId?: string;
      cardId: string;
    },
  ) {}
}

export class GetCVVDetailsSuccess {
  static readonly type = '[CardManagement] GetCVVDetailsSuccess';
  constructor(public response: SecureCardInfoResponse) {}
}

export class LostCard {
  static readonly type = '[CardManagement] LostCard';
  constructor(public formData: ReplaceCardRequest) {}
}

export class ViewPin {
  static readonly type = '[CardManagement] ViewPin';
  constructor(
    public payload: {
      secureToken: string;
      accountId: string;
      cardId: string;
    },
  ) {}
}

export class ViewPinSuccess {
  static readonly type = '[CardManagement] ViewPinSuccess';
  constructor(public response: SecureCardInfoResponse) {}
}

export class MakeVirtualCard {
  static readonly type = '[CardManagement] MakeVirtualCard';
  constructor(public accountId: string) {}
}

export class MakePhysicalCard {
  static readonly type = '[CardManagement] MakePhysicalCard';
  constructor(public accountId: string) {}
}

export class DeleteVirtualCardAction {
  static readonly type = '[CardManagement] DeleteVirtualCardAction';
  constructor(public payload: { cardId: string; accountId: string }) {}
}

export class CancelCardAction {
  static readonly type = '[CardManagement] CancelCardAction';
  constructor(public cardId: string) {}
}
